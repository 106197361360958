import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Tab, Nav, Table, Form } from 'react-bootstrap';
import config from '../config/config';
import Header from "./directories/Header";
import Footer from "./directories/Footer";
import { BsDiscord } from 'react-icons';
import { AiOutlineTwitter } from 'react-icons/ai';
import { getUserById, addProfilePictute, addWalletAddress } from "../Action/user.action"
import Cookies from "js-cookie";
import toast, { Toaster } from 'react-hot-toast';

const Setting = () => {


    let userId = Cookies.get("userId")
    let token = Cookies.get("token")
    
    if (!(token)) {
        window.location.href = `${config.baseUrl}`;
    }

    const [data, setdata] = useState({})
    const [imagePreview, setImagePreview] = useState('')
    const [imageFile, setImageFile] = useState()
    const [walletAddress, setwalletAddress] = useState();


    useEffect(() => {
        userDetails();
    }, [])
    const userDetails = async () => {
        let userD = await getUserById({ "userId": userId });
        setdata(userD.data)
    }



    const logout = (e) => {
        e.preventDefault()

        Cookies.remove("userId");
        window.location.href = `${config.baseUrl}`;

    }

    const partnerPic = async (e) => {
        e.preventDefault();

        let image_as_base64 = URL.createObjectURL(e.target.files[0]);
        let image_as_files = e.target.files[0];


        setImageFile(image_as_files);
        setImagePreview(image_as_base64);
    };


    const submit = async (e) => {
        e.preventDefault();
        if (imageFile == undefined && walletAddress == undefined) {
            toast.error("Please  Select Image to change or enter wallet address")
            return
        }
        if (imageFile != undefined) {
            let data = {
                profile_pic: imageFile,
                "userId": userId
            }
            let ss = await addProfilePictute(data)
            // toast.success(ss.msg)
            // setTimeout(() => {
            //     window.location.href = `${config.baseUrl}`;
            // }, 3000)
        }

        if (walletAddress != undefined && data[0].wallet_address == '') {
            if (walletAddress.length >= 42) {


                let data = {
                    wallet_address: walletAddress,
                    "userId": userId
                }
                let ss = await addWalletAddress(data)

            }
        }

        toast.success("Profile Updated")
        setTimeout(() => {
            window.location.href = `${config.baseUrl}`;
        }, 3000)
    }




    const inputHandler = (e) => {
        setwalletAddress(e.target.value)
    }

    return (
        <>
            <Toaster />

            <Header />
            <section className='setting pt-5'>
                {data[0] ?
                    <Container>
                        <Row>
                            <Col md={8} xs={12} className='mx-auto'>
                                <div className='top_profile mt-4'>
                                    <div>
                                        <div className='dappicon mx-auto mb-5'>

                                            <a href={`${config.baseUrl}connectWallet`}>
                                                {

                                                    imagePreview != '' ?
                                                        <img src={imagePreview} loading="lazy" className='lazybox' />

                                                        :
                                                        data[0].profile_pic != '' ?
                                                            <img src={`${config.imageURL}` + `${data[0].profile_pic}`} loading="lazy" className='lazybox' />

                                                            :
                                                            <img src="images/imgpsh_fullsize_anim1.png" loading="lazy" className='lazybox' />

                                                }
                                                <Form.Group controlId="formFileLg" className="">
                                                    <Form.Control type="file" size="lg" onChange={e => { partnerPic(e) }} />
                                                </Form.Group>


                                            </a>

                                        </div>

                                        <span><i class="fa fa-pencil" aria-hidden="true"></i></span>
                                    </div>

                                    <div className='profile-text text-center'>
                                        <h2>{data[0].userName}</h2>

                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} xs={12} className='mx-auto py-5'>
                                <Form className='wallet_form' autoComplete='off'>
                                    <Form.Group className="mb-3">
                                        <span>Username:</span>
                                        {/* <Form.Control type="lable" value="Username" /> */}
                                        <Form.Control type="text" placeholder="User Name" value={data[0].userName} />
                                    </Form.Group>

                                    <Form.Group className="mb-5" controlId="formBasicEmail">
                                        <span>Email:</span>

                                        <Form.Control type="email" placeholder="Enter Email" value={data[0].email} />
                                    </Form.Group>
                                    {data[0].wallet_address != '' ?
                                        <><Form.Group className="mb-5" controlId="formBasicEmail">
                                            <span>Wallet Address for Referral Tickets:</span>

                                            <Form.Control type="text" placeholder="Enter wallet address" value={data[0].wallet_address} />
                                        </Form.Group>
                                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                                <span>Referral Code:</span>

                                                <Form.Control type="text" placeholder="Your referral code" value={data[0].rafferral_id > 0 ? data[0].rafferral_id : `Please Contact to admin`} />
                                            </Form.Group>
                                        </> :
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <span>Wallet Address for Referral Tickets:</span>

                                            <Form.Control type="text" placeholder="Enter wallet address" value={walletAddress} onChange={e => { inputHandler(e) }} />
                                        </Form.Group>

                                    }



                                    <div className=''>
                                        <Form.Group className=" communi_box">
                                            <button class="btn btn-primary px-4 p-2 rounded-1" onClick={e => { submit(e) }}>SUBMIT</button>
                                        </Form.Group>
                                    </div>
                                    {/* {data[0].wallet_address == '' && <div className='text-center'>
                                        <Form.Group className="mb-4 mt-3 communi_box">
                                            <button class="btn btn-primary" onClick={e => { submitwalletAddress(e) }}>SUBMIT Wallet Address</button>
                                        </Form.Group>
                                    </div>} */}
                                    {/* <div className='text-center'>
                                        <Form.Group className="mb-4 mt-3 communi_box">
                                            <button class="btn btn-primary" onClick={e => { logout(e) }}>LOG OUT</button>
                                        </Form.Group>

                                    </div> */}


                                </Form>
                            </Col>
                        </Row>

                    </Container>
                    :
                    <></>}
            </section>

            <Footer />

        </>
    )
}

export default Setting;




