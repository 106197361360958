import React, { useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Header from "./directories/Header";
import Footer from "./directories/Footer";
import Modal from 'react-bootstrap/Modal';
import validator from 'validator'
import toast, { Toaster } from 'react-hot-toast';
import config from "../config/config";
import {loginuser} from "../Action/user.action"
import Cookies from "js-cookie";


const Login = (props) => {
    let userId = Cookies.get("userId")
    let token = Cookies.get("token")

    if(token){
        window.location.href = `${config.baseUrl}`;
    }
    const [show, setShow] = useState(false);
    const [form,setForm] = useState({userName:'',password:''})
    const [formError,setFormError] = useState({userName:'',password:''})
   

    
    

const onChange=(e)=>{
   
    setForm({...form,[e.target.name]:e.target.value})

}

const submit=async(e)=>{
    e.preventDefault();

    let verify = verification();
    if(verify){
        let loginuserResult = await loginuser(form)
        if(loginuserResult.status){
            Cookies.set("userId", loginuserResult.data);
            Cookies.set("token", JSON.stringify(loginuserResult.token));

            toast.success("Login Successful")

            setTimeout(() => {
                window.location.href = `${config.baseUrl}`;
        
            }, 1000);
        }else{
            toast.error(loginuserResult.msg)
        }
    }else{
    }

}


const verification=()=>{
  
    let userNameError = '';
    let passwordError = '';
 
    if(form.userName == ''){
        userNameError='Invalid user name'
    }
    if(form.password == '' ){
        passwordError = 'Invalid Password'
    }
    

    if( userNameError == '' && passwordError == ''){
        setFormError({userName:userNameError,password:passwordError})
        return true;
    }else{
        setFormError({userName:userNameError,password:passwordError})
        return false;
    }
}



    return (

        <>
         <Toaster />
            <Header />
            <section className='connect_wallet mt-5 pt-5 mb-5'>
                <Container>
                    <Row>
                        <Col md={6} xs={12} className='mx-auto p-4 pt-5 wallet_form '>
                            <div className='text-center mb-4'>
                                <h2>Login</h2>
                            </div>
                            <Form className='mb-4' autoComplete='off'>
                                <Form.Group className="mb-3">
                                <Form.Label >User Name</Form.Label>
                                    <Form.Control type="text" placeholder="User Name or Email Id" name="userName" value={form.userName}  onChange={e=>{onChange(e)}} />
                                    {
                                    formError.userName==''
                                    ?
                                    <span></span>
                                    :
                                    <span style={{color:'red'}}>{formError.userName}</span>
                                    }
                                </Form.Group>

                            
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label >Password</Form.Label>
                                    <Form.Control type="password" placeholder="Password"  name="password" value={form.password} onChange={e=>{onChange(e)}} />
                                    {
                                    formError.password==''
                                    ?
                                    <span></span>
                                    :
                                    <span style={{color:'red'}}>{formError.password}</span>
                                    }
                                </Form.Group>

                                


                                <Button variant="primary" type='submit' className='themebtn me-3' onClick={e=>{submit(e)}}>
                                    Submit
                                </Button>

                            </Form>
                            <p>New to Dappwin?<a href={`${config.baseUrl}connectWallet`}>&nbsp;SignUp</a></p>
                            <p>Forgot Your Password?&nbsp;:<a href={`${config.baseUrl}forgetPassword`}>&nbsp;Password Reset</a></p>

                        </Col>
                    </Row>
                </Container>
            </section>

            <Footer />



         


        </>

    )
}

export default Login;
