import React, { useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Header from "./directories/Header";
import Footer from "./directories/Footer";
import Modal from 'react-bootstrap/Modal';
import validator from 'validator'
import toast, { Toaster } from 'react-hot-toast';
import config from "../config/config";
import {sendMailReq,newUserRegister,checkReferralId,loginuser,verifyUserName,updateUser, checkEmailExist,verifyOTP,sendEmailOTP ,updateEmailInTransaction} from "../Action/user.action"
import Cookies from "js-cookie";
import Web3 from 'web3'


const Promo = (props) => {
   
    const [show, setShow] = useState(false);
    const [form,setForm] = useState({email:''})
    const [formError,setFormError] = useState({email:''})
    const [id,setId] = useState()
    const [otp,setOtp] = useState();


const onChange=(e)=>{
   
    setForm({...form,[e.target.name]:e.target.value})

}


const submit=async(e)=>{
    e.preventDefault();
    let verify = verification();
   
    
    if(await verify){
        
        let verifyAPI = await checkEmailExist(form);
        if(verifyAPI.status){
            setId(verifyAPI.data)
        toast.success(verifyAPI.msg)
        if(verifyAPI.status1 == 0){
        setShow(true)
        //verify email
        }else if(verifyAPI.status1 == 2){
            window.location.href = `${config.baseUrl}promo/${verifyAPI.data}`;
        }
        }else{
        toast.error(verifyAPI.msg)
        }
    }else{

    }

}


const verification=async()=>{
    let emialError = '';
   
    if(!(validator.isEmail(form.email)) || form.email==''){
        emialError='Invalide Email'
    }
   

    if(emialError == ''  
    ){
        setFormError({email:emialError
        })
        return true;
    }else{
        setFormError({email:emialError
        })
        return false;
    }
}

const verifyOtp=async(e)=>{
e.preventDefault();
let data = {
    otp:otp,
    email:form.email,
}
let verificationOtp = await verifyOTP(data)
if(verificationOtp.status){
    toast.success(verificationOtp.msg)
    window.location.href = `${config.baseUrl}promo/${id}`;
    

    // setShow(false)

    // setPromoForm(true)
}else{
    toast.error(verificationOtp.msg)

}
}



    return (

        <>
         <Toaster />
            <Header />
            <section className='connect_wallet'>
                <Container>
                    <Row>
                        <Col md={8} xs={12} className='mx-auto py-5'>
                           
                            <Form className='wallet_form mb-4' autoComplete='off' onSubmit={e=>{submit(e)}} >
                           

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label >Email Id</Form.Label>
                                    <Form.Control type="email" placeholder="Your Email Address"  name="email" value={form.email} onChange={e=>{onChange(e)}} />
                                    {
                                    formError.email==''
                                    ?
                                    <span></span>
                                    :
                                    <span style={{color:'red'}}>{formError.email}</span>
                                    }
                                </Form.Group>

                               

                               
                 
                                <Button variant="primary" type='submit' className='themebtn me-3' onClick={e=>{submit(e)}}>
                                    Submit
                                </Button>

                            </Form>
                            
                           

                        </Col>
                    </Row>
                </Container>
            </section>

            <Footer />


            {/*-------------------------- Modal Connect Wallet --------------------------- */}

            <Modal show={show}  className='verify_code' {...props}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                <Modal.Header closeButton>
                    <Modal.Title>Please Check Your Email</Modal.Title>
                    {/* <Modal
                        {...props}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    ></Modal> */}
                </Modal.Header>
                <Modal.Body className='py-0'>
                    <Form className='' autoComplete='off' onSubmit={e => { verifyOtp(e) }}>
                        <Form.Group className="mb-3">
                            <Form.Label>Verify Code</Form.Label>
                            <Form.Control type="number" placeholder="Enter One Time Password" value={otp} onChange={e=>{setOtp(e.target.value)}} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="primary" onClick={e=>{verifyOtp(e)}} className='themebtn mx-auto'>
                    SUBMIT
                    </Button>
                </Modal.Footer>
            </Modal>



        </>

    )
}

export default Promo;
