import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  Tab,
  Modal,
  Form,
} from "react-bootstrap";
import Header from "./directories/Header";
import Footer from "./directories/Footer";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { Fade, Zoom } from "react-reveal";
import { useSelector } from "react-redux";
import config from "../config/config";
import RaffleManagementABI from "../config/RaffleManagementABI.json";
import Web3 from "/node_modules/web3/dist/web3.min.js";
import toast, { Toaster } from "react-hot-toast";
import validator from "validator";
import commaNumber from "comma-number";
import Cookies from "js-cookie";
import BigNumber from "bignumber.js";
import {
  registerUser,
  checkSpinAvailableByWalletAddress,
  updateResult,
  getAllSpinTransactions,
  chancesPercentageApi,
  checkFreeSpinAvailableByWalletAddress,
  updateFreeSpinAPI,
  spinTheWheelOpts
} from "../Action/user.action";

import {
  getUserById,
  getTransactionDetailsById,
  checkActiveAccount,
  sendWinnerMail,
  updateEmailInTransaction,
  endRaflle,
  getTransactionDetailsByIndexNumber,
  discreption,
  discreptionSpinTheWheel,
  getTotalSoldTickets,
  insertTransaction,
  getAllTransactionDetail,
  getActivity,
  getTotalKai,
  sendMailReq,
  userId,
} from "../Action/user.action";

import WheelComponent from "react-wheel-of-prizes";
import WheelComponent2 from "../componets/wheel2";
import "../componets/spinthewheel.css";
import WheelComponent3 from "./wheel3";
import { FaSpinner } from "react-icons/fa";

import Sound from "react-sound";
import VictorySound from "../config/VictorySound.mp3";
import LoseSound from "../config/LoseSound.mp3";
import SoundSpinner from "../config/Spin Prize Wheel 8sec.mp3";
import "../componets/spinthewheel.css";


const SpinTheWheel = () => {


  const [segments, setSegments] = useState([
    "DOUBLE",
    "NOTHING",
    "DOUBLE",
    "NOTHING",
    "DOUBLE",
    "NOTHING",
    "DOUBLE",
    "NOTHING",
    "DOUBLE",
    "NOTHING",
    "DOUBLE",
    "NOTHING",
    "DOUBLE",
    "NOTHING",
    "DOUBLE",
    "NOTHING",
  ]);
  const [segColors, setSegColors] = useState([
    "#34a24f",
    "#EE4040",
    "#34a24f",
    "#EE4040",
    "#34a24f",
    "#EE4040",
    "#34a24f",
    "#EE4040",
    "#34a24f",
    "#EE4040",
    "#34a24f",
    "#EE4040",
    "#34a24f",
    "#EE4040",
    "#34a24f",
    "#EE4040",
  ]);
  let userId = Cookies.get("userId");

  let token = Cookies.get("token")

  const [soundTrack, setSoundTrack] = useState();
  const [playSound, setPlaySound] = useState(false);
  const [discreptions, setDiscreption] = useState([]);
  const [report, setReport] = useState([]);
  const [walletConnected, setWalletConnected] = useState(false);
  const [userWalletAddress, setUserWalletAddress] = useState("");
  const [walletName, setWalletName] = useState("");
  const [resultIndex, setResultIndex] = useState();
  const [chancePercentage, setChancePercentage] = useState(0.45);
  const [connectWalletLoader, setConnectWalletLoader] = useState(false);
  const [amountTransferLoader, setAmountTransferLoader] = useState(false);
  const [spinAvailable, setSpinAvailable] = useState(false);
  const [getAmount, setAmount] = useState("100");
  const [freespins, setFreeSpins] = useState(0);
  const [options,setOption] = useState([])
  useEffect(() => {
    getDiscreption();
    getSpinAvailable();
    getAllSpinTransactionsApi();
    getChancesPercentage();
    spinTheWheelOptions();
  }, [walletConnected, amountTransferLoader, spinAvailable]);
  // useEffect(() => {
  //   getDiscreption();
  //   getSpinAvailable();
  //   getAllSpinTransactionsApi();
  //   getChancesPercentage();
  // }, [walletConnected]);

const spinTheWheelOptions=async()=>{
  try{
let result = await spinTheWheelOpts();
setOption(result.data)
  }catch(error){
    console.log(error)
  }
}
  const connectWalletFunc = async (e) => {
    e.preventDefault();
    if (!userId) {
      toast.error("Please login");
      return;
    }
    let getWallet_address = await getUserById({ userId });
    getWallet_address = getWallet_address.data[0].wallet_address;

    setConnectWalletLoader(true);
    try {
      let web3;

      if (window.kardiachain) {

        let kardiaWallet = await window.kardiachain.request({
          method: "eth_requestAccounts",
        });

        if (kardiaWallet[0].toUpperCase() != getWallet_address.toUpperCase()) {
          toast.error("Please connect to registered wallet address");
          setConnectWalletLoader(false);
          return;
        }

        setUserWalletAddress(kardiaWallet[0]);
        localStorage.setItem("walletAddressFreeSpin", kardiaWallet[0]);
        setWalletConnected(true);
        setWalletName("kardiawallet");
        setConnectWalletLoader(false);
        openSpinner();
      } else if (window.ethereum) {

        let metamsakAccount = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        if (
          metamsakAccount[0].toUpperCase() != getWallet_address.toUpperCase()
        ) {
          toast.error("Please connect to registered wallet address");
          setConnectWalletLoader(false);

          return;
        }
        setUserWalletAddress(metamsakAccount[0]);
        localStorage.setItem("walletAddressFreeSpin", metamsakAccount[0]);

        web3 = new Web3(window.ethereum);

        const currentChainId = await web3.eth.net.getId();

        if (currentChainId !== config.chainId) {
          try {
            const data = await web3.currentProvider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: Web3.utils.toHex(config.chainId) }],
            });
          } catch (switchError) {
            if (switchError.code === 4902) {
              toast.error("Please connect with Kardichain network");
              setConnectWalletLoader(false);
              return false;
            }
          }
        }

        setWalletConnected(true);
        setWalletName("metamask");
        setConnectWalletLoader(false);
        openSpinner();
      } else {
        alert("Please Install metamask or kardia wallet");
        setConnectWalletLoader(false);
      }
    } catch (error) {
      setConnectWalletLoader(false);
      setWalletConnected(false);

      console.log(error);
    }
  };



  const getDiscreption = async () => {
    let result = await discreptionSpinTheWheel();

    setDiscreption(result.data[0].content);
  };

  const getChancesPercentage = async () => {
    try {
      let result = await chancesPercentageApi();
      setChancePercentage(result.data[0].percentage);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllSpinTransactionsApi = async () => {
    try {
      let result = await getAllSpinTransactions();
      setReport(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSpinAvailable = async () => {
    try {
      console.log("check spin available 1234")
      if (walletConnected) {
        let data = {
          walletAddress: userWalletAddress,
        };

        let check = await checkFreeSpinAvailableByWalletAddress(data);
        if (check.status && check.data > 0) {
          setFreeSpins(check.data);
        } else {
          console.log("check spin available")
          let result = await checkSpinAvailableByWalletAddress(data);
          setSpinAvailable(true);

          if (result.status) {
            if (result.data.available) {
              setSpinAvailable(true);
              localStorage.setItem("transactionId", result.data.transactionId);
            } else {
              setSpinAvailable(false);
            }
          } else {
            setSpinAvailable(false);
          }
        }
      }
    } catch (error) { }
  };

  const transfer = async (e) => {
    e.preventDefault();

    setAmountTransferLoader(true);
    try {
      let web3;
      if (walletName == "kardiawallet") {
        web3 = new Web3(window.kardiachain);
      } else {
        web3 = new Web3(window.ethereum);
      }

      let balance = await web3.eth.getBalance(userWalletAddress);

      balance = balance / 10 ** 18;
      let amount = getAmount;
      if (balance <= amount) {
        toast.error("Insufficient Balance. Try with less amount.");
        setAmountTransferLoader(false);
        return;
      }
      let value = (amount * 10 ** 18).toLocaleString("fullwide", {
        useGrouping: false,
      });
      let gasPrice = await web3.eth.getGasPrice();
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: config.SpinTheWheelWalletAddress,
        from: userWalletAddress,
        value: value,
      });

      let trx = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: config.SpinTheWheelWalletAddress,
        from: userWalletAddress,
        value: value,
      });

      if (trx.transactionHash) {
        let data = {
          userId: userId,
          trxhash: trx.transactionHash,
          amount: amount,
          userWalletAddress: userWalletAddress,
        };
        let result = await registerUser(data);
           toast.success("Transaction successful. You can spin now");
         
       openSpinner();
        setSpinAvailable(true)
     }
        setAmountTransferLoader(false);
      
    } catch (error) {
      toast.error(error.data ? error.data.message : error.message);
      setAmountTransferLoader(false);
    }
  };



  useEffect(() => {
    openSpinner();
  }, []);

  const openSpinner = async () => {
    //e.preventDefault();

    // Set the desired probability (45% in this case)
    const probabilityThreshold = chancePercentage;

    // Generate a random number between 0 and 1
    const randomValue = Math.random();
  
    // Check if the random value is less than the probability threshold
    if (randomValue < probabilityThreshold) {
      setResultIndex(0);
    } else {
      setResultIndex(1);
    }

    // setOpenSpinnerModal(true);
  };
  const onFinished = async (winner) => {
    setTrack(winner);


    let resu =await updateAPI(winner);


    setTimeout(() => {
      setPlaySound(false);

      setSpinAvailable(false);
      if (winner == "DOUBLE") {
        toast.success("Congratulations: You Won Double KAI");
      } else {
        toast.error("Sorry!! Better luck next time");
      }
    }, 5000);
  };

  const updateAPI = async (winner) => {
    try {
      let data = {
        result: winner == "DOUBLE" ? 1 : 2,
        id: localStorage.getItem("transactionId"),
      };
      let result = await updateResult(data);
      return {status:result.status,message:result.msg}
    } catch (error) {
      console.log(error);
      return {status:false,message:error}
    }
  };

  const onFinishedFreeSpin = async (winner) => {
    // setFreeSpins(0);
    getSpinAvailable();
  
    setTrack(winner);
    // setPlaySound(false)
  
    updateFreeSpinAPCall(winner);
  
    setTimeout(() => {
      setPlaySound(false);
  
      if (winner === "DOUBLE") {
        toast.success("Congratulations: You Won Double KAI");
  
        setTimeout(() => {
          // Reload the page after 2 seconds
          window.location.reload();
        }, 2000);
      } else {
        toast.error("Sorry!! Better luck next time");
        setTimeout(() => {
          // Reload the page after 2 seconds
          window.location.reload();
        }, 2000);
        // Assuming you don't want to reload for "Sorry" case
        // Add other logic here if needed
      }
    }, 5000);
  };
  
  const updateFreeSpinAPCall = async (winner) => {
    try {
      let data = {
        result: winner == "DOUBLE" ? 1 : 2,
        walletAddressFreeSpin: localStorage.getItem("walletAddressFreeSpin"),
      };
      let result = await updateFreeSpinAPI(data);
    } catch (error) {
      console.log(error);
    }
  };

  const setTrack = (winner) => {
    if (winner == "DOUBLE") {
      setSoundTrack(VictorySound);
    } else {
      setSoundTrack(LoseSound);
    }
    setPlaySound(true);
  };

  const handleChangeAmount = async (e) => {
    const selectedValue = parseInt(e.target.value); // Convert to integer if necessary

    // Check if the selected value exists in the options array
    const isValidOption = options.some(option => option.amount === selectedValue);
  
    if (isValidOption) {
      setAmount(selectedValue);
    } else {
      alert('Invalid amount selected'); // Optionally handle invalid selection
      setAmount(100)
    }
  };

  return (
    <>
      <Header />
      
      <Sound
        url={soundTrack}
        playStatus={playSound ? Sound.status.PLAYING : Sound.status.STOPPED}
      />
      {/* <section className="doublebox"> */}
      <section className="">
        <Container>
        <Toaster />
          <Row>
            <Col lg={12}>
              <div className="ticket-details">
                <Row className="">
                  {/* <Col md={4} className="mt-4">
                    <div className="description-section">
                      <div className="detail-image">
                        <img src="images/imgpsh_fullsize_anim1.png" />
                        <h3>DESCRIPTION</h3>

                        <div
                          style={{ lineHeight: "22px" }}
                          dangerouslySetInnerHTML={{ __html: discreptions }}
                        />
                        <hr />
                      </div>
                    </div>
                  </Col> */}
                  <Col lg={12} md={12} xs={12} className="mt-4">
                    <div className="details-name">
                      <div className="ticket-name">
                        <h2>DOUBLE OR NOTHING</h2>
                        <img src="images/check.png" />
                      </div>
                      <div className="price-detail">
                        <h3>#25269</h3>
                      </div>
                      <hr />
                      <div className="card-area">
                        <div
                          className="selected-cards"
                          style={{ minHeight: "431px" }}
                        >
                          <div className="text-center">
                            {!walletConnected ? (
                              <h3 className="mb-3">
                                CONNECT WALLET TO PARTICIPATE
                              </h3>
                            ) : (
                              <h3 className="mb-3">CONNECTED WALLET ADDRESS</h3>
                            )}
                          </div>
                          <div className="connect-btn mb-4">
                            {!walletConnected ? (
                              <Button
                                className="border-btn px-4"
                                onClick={(e) => {
                                  connectWalletFunc(e);
                                }}
                                disabled={connectWalletLoader ? true : false}
                              >
                                {connectWalletLoader
                                  ? "Loading..."
                                  : "Connect Wallet"}
                              </Button>
                            ) : (
                              <Button className="border-btn px-5">
                                {`${userWalletAddress
                                  .toString()
                                  .substring(0, 4)}....${userWalletAddress
                                    .toString()
                                    .substring(
                                      userWalletAddress.length - 4,
                                      userWalletAddress.length
                                    )}`}
                              </Button>
                            )}
                          </div>
                          <div className="text-center">
                            {walletConnected ? (
                              <Form
                                className="wallet_form p-0 mb-4 overflow-hidden"
                                autoComplete="off"
                              >
                                <Form.Group className="mb-20">
                                  <Row>
                                    {freespins <= 0 ? (
                                      <Col lg={12} className="pt-3">
                                        <Container>
                                          <Row className="justify-content-center">
                                            <Col xxl={6} xl={7} lg={9} md={8}>
                                              <Row className="align-items-center">
                                                <Col lg={4} md={6} className="mb-3">
                                                  {" "}
                                                  <span cla>WIN OR LOSE </span>
                                                </Col>
                                                <Col lg={4} md={6} className="mb-3">
                                                  {" "}
                                                  <select
                                                    className="border-1 outline-none bg-black border-secondary rounded-5 p-1 text-white"
                                                    onChange={(e) => {
                                                      handleChangeAmount(e);
                                                    }}
                                                  >
                                                    {options.map((item)=>(<option value={item.amount}>{item.amount} KAI</option>))}
                                                    {/* <option value="100">
                                                      100 KAI
                                                    </option>
                                                    <option value="400">
                                                      400 KAI
                                                    </option>
                                                    <option value="1000">
                                                      1000 KAI
                                                    </option>
                                                    <option value="4000">
                                                      4000 KAI
                                                    </option>
                                                    <option value="10000">
                                                      10,000 KAI
                                                    </option> */}
                                                  </select>
                                                </Col>
                                                <Col lg={4} className="mb-3">
                                                  {" "}
                                                  <Button
                                                  id="targetElementId"
                                                    variant="green"
                                                    className="border-1"
                                                    onClick={(e) => {
                                                      transfer(e);
                                                    }}
                                                    disabled={
                                                      amountTransferLoader ||
                                                        spinAvailable
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    <b>PLAY NOW</b>
                                                  </Button>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Container>


                                        {amountTransferLoader && (
                                          <div className="processing-loader fs-4 py-3">
                                            <FaSpinner className="fa-spin" />
                                            &nbsp;
                                            <span className="fs-6">
                                              PROCESSING...
                                            </span>
                                          </div>
                                        )}
                                      </Col>
                                    ) : (
                                      <Col lg={12} className="py-3 text-center">
                                      
                                           {freespins==1 ?
                                            <div >
                                              <h5>
                                                You have {freespins} Free spin{" "}
                                              </h5>{" "}

                                            </div> 
                                            :                                               
                                            <div >
                                              <h5>
                                                You have {freespins} Free spins{" "}
                                              </h5>{" "}

                                            </div>  }
                                     


                                        {/* {amountTransferLoader &&  <div className="processing-loader fs-4 py-3"><FaSpinner  className="fa-spin" />&nbsp;<span className="fs-6">PROCESSING...</span></div>} */}
                                      </Col>
                                    )}
                                  </Row>
                                </Form.Group>
                              </Form>
                            ) : (
                              ""
                            )}
                          </div>

                          <Row>
                            <Col lg={12} mb={12} className="mb-3 spinbox">
                              {freespins > 0 && (
                                <WheelComponent2
                                  segments={segments}
                                  segColors={segColors}
                                  winningSegment={segments[resultIndex]}
                                  onFinished={(winner) => {
                                    onFinishedFreeSpin(winner);
                                  }}
                                  primaryColor={"green"}
                                  width="100%"
                                  contrastColor="white"
                                  buttonText="Spin"
                                  isOnlyOnce={true}
                                  size={260}
                                  upDuration={450}
                                  downDuration={100}
                                  className="wheel"
                                />
                              )
                              
                              } 

                             {spinAvailable && (
                                <WheelComponent2
                                  segments={segments}
                                  segColors={segColors}
                                  winningSegment={segments[resultIndex]}
                                  onFinished={(winner) => {
                                    onFinished(winner);
                                  }}
                                  primaryColor={"green"}
                                  width="100%"
                                  contrastColor="white"
                                  buttonText="Spin"
                                  isOnlyOnce={true}
                                  size={260}
                                  upDuration={450}
                                  downDuration={100}
                                  className="wheel"
                                />
                              )}
                              {(!spinAvailable && freespins <= 0)&& (
                                <WheelComponent3
                                  segments={segments}
                                  segColors={segColors}
                                  primaryColor="grey"
                                  width="100%"
                                  contrastColor="white"
                                  buttonText="Spin"
                                  size={260}
                                />
                              )}

                              {/* {freespins > 0 ?
                                (
                                  <WheelComponent2
                                    segments={segments}
                                    segColors={segColors}
                                    winningSegment={segments[resultIndex]}
                                    onFinished={(winner) => {
                                      onFinishedFreeSpin(winner);
                                    }}
                                    primaryColor={"green"}
                                    width="100%"
                                    contrastColor="white"
                                    buttonText="Spin"
                                    isOnlyOnce={true}
                                    size={260}
                                    upDuration={450}
                                    downDuration={100}
                                    className="wheel"
                                  />
                                )
                                :
                                spinAvailable ? (
                                  <WheelComponent2
                                    segments={segments}
                                    segColors={segColors}
                                    winningSegment={segments[resultIndex]}
                                    onFinished={(winner) => {
                                      onFinished(winner);
                                    }}
                                    primaryColor={"green"}
                                    width="100%"
                                    contrastColor="white"
                                    buttonText="Spin"
                                    isOnlyOnce={true}
                                    size={260}
                                    upDuration={450}
                                    downDuration={100}
                                    className="wheel"
                                  />
                                )
                                  :
                                  (
                                    <WheelComponent3
                                      segments={segments}
                                      segColors={segColors}
                                      primaryColor="grey"
                                      width="100%"
                                      contrastColor="white"
                                      buttonText="Spin"
                                      size={260}
                                    />
                                  )
                              } */}

                              <div className="btn-gradient-1 "></div>
                            </Col>
                          </Row>
                        </div>
                        <div className="selected-cards mt-4 overflow-hidden pt-0 px-0">
                          <Container>
                            <Tab.Container
                              id="left-tabs-example"
                              defaultActiveKey="second"
                            >
                              <Row>
                                <Nav
                                  variant="pills"
                                  className="border-bottom border-dark bg-gray pt-2"
                                >
                                  <Nav.Item>
                                    <Nav.Link
                                      eventKey="second"
                                      className="fs-5 border-0 pb-2"
                                    >
                                      PARTICIPANTS
                                    </Nav.Link>
                                  </Nav.Item>
                                </Nav>

                                <Tab.Content className="mt-4">
                                  <Tab.Pane eventKey="second">
                                    <div className="member">
                                      <ul>
                                        {report.length > 0 ? (
                                          report.map((item) => (
                                            <li>
                                              <div className="list-name">
                                                <a className="d-flex align-items-center">
                                                  <div>
                                                    {item.profile_pic != "" &&
                                                      item.profile_pic != null ? (
                                                      <span>
                                                        <img
                                                          src={
                                                            `${config.imageURL}` +
                                                            `${item.profile_pic}`
                                                          }
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img src="images/logo.png" />
                                                      </span>
                                                    )}
                                                  </div>
                                                  <div>
                                                    <h3 className="block mb-0 text-primary fs-6">
                                                      {/* {item.walletAddress
                                                        .toString()
                                                        .substring(0, 3)}
                                                      ...
                                                      {item.walletAddress
                                                        .toString()
                                                        .substring(
                                                          item.walletAddress
                                                            .length - 3,
                                                          item.walletAddress
                                                            .length
                                                        )} */}
                                                          {
                                                        <>{item.userName}</>
                                                        }
                                                    </h3>
                                                    <div className="">
                                                      <table>
                                                        <tr>
                                                          <td className="py-0 small text-muted">
                                                            {" "}
                                                            Amount :{" "}
                                                          </td>
                                                          <td className="py-0 small">
                                                            {commaNumber(
                                                              item.amount
                                                            )}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="py-0 small text-muted">
                                                            {" "}
                                                            Result :{" "}
                                                          </td>
                                                          <td className="py-0 small">
                                                            {" "}
                                                            {item.result == "1"
                                                              ? `WINNER`
                                                              : `not this time`}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="py-0 small text-muted">
                                                            {" "}
                                                            Time :
                                                          </td>
                                                          <td className="py-0 small">
                                                            {item.timestamp.toString()
                                                        .substring(0, 10)}  {item.timestamp.toString()
                                                          .substring(11, 20)}
                                                          </td>
                                                        </tr>
                                                      </table>
                                                    </div>

                                                    {/* <div className="uppercase">

                                                      Time : {item.timestamp}
                                                    </div> */}
                                                  </div>
                                                </a>
                                              </div>
                                            </li>
                                          ))
                                        ) : (
                                          <li>
                                            <div className="list-name">
                                              <div><a>No Data Found</a>
                                              </div>
                                            </div>
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              </Row>
                            </Tab.Container>
                          </Container>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};
export default SpinTheWheel;
