import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Breadcrumb, Form, Button } from 'react-bootstrap';
import Header from "./directories/Header";
import Footer from "./directories/Footer";
import { Link } from "react-router-dom";
import config from "../config/config";
import { Fade, Roll, Zoom } from 'react-reveal';
import validator from 'validator'
import { contactus,officeDetails,sendEmailToSupport } from "../Action/user.action"

const Contactus = () => {

const [formValue,setFormValue] = useState({"name":"","email":"","phoneNo":"","message":""});
const [errorFormValue,setErrorFormValue] = useState({"name":"","email":"","phoneNo":"","message":""});
const [officeDetail,setOfficeDetails] = useState([])


useEffect(() => {
  getOfficeDetails()
}, []);
const getOfficeDetails=async()=>{
   let result= await officeDetails()
   if(result.status){
    setOfficeDetails(result.data[0].content)
   }

}

const onChange=(e)=>{
    if(e.target.name=='name'){
        setErrorFormValue({...errorFormValue,[e.target.name]:''})
    }
    if(e.target.name=='phoneNo'){
        setErrorFormValue({...errorFormValue,[e.target.name]:''})
    }
    if(e.target.name=='email'){
        setErrorFormValue({...errorFormValue,[e.target.name]:''})
    }
    if(e.target.name=='message'){
        setErrorFormValue({...errorFormValue,[e.target.name]:''})
    }
   
    
setFormValue({...formValue,[e.target.name]:e.target.value})
}

const submit=async(e)=>{
    e.preventDefault();
    let verification =verify()

if(verification){
    let result = await contactus(formValue);
    let supportEmail  = await sendEmailToSupport(formValue)
    setTimeout(() => {
       

       window.location.reload();
   }, 1000);
}
}

const verify=()=>{
   let nameError = '';
   let emialError = '';
   let phoneError = '';
   let messageError = '';

    if(formValue.name==''){
      

        nameError='Invalide Name'
    }
    if(!(validator.isEmail(formValue.email)) || formValue.email==''){


        emialError='Invalide Email'
    }
    if((!validator.isMobilePhone(formValue.phoneNo)) || formValue.phoneNo=='' || formValue.phoneNo.length<10){

        phoneError='Invalide Phone Number'   
    }
    if(formValue.message==''){

        messageError='Invalide Message'
    }
    if(nameError!='' || messageError!='' || phoneError!='' || emialError!=''){
        setErrorFormValue({name:nameError,email:emialError,phoneNo:phoneError,message: messageError})
        return false;
    }else{
        return true;
    }
}


    return (
        <>
            <Header />
            <Breadcrumb>
                <Container>
                    <Row>
                        <Col lg={12} className="text-center">
                            <h2>Contact us</h2>
                        </Col>
                    </Row>
                </Container>
            </Breadcrumb>
            <section className="contactus">
                <Container>
                    <Row>
                        <Col lg={8} className="mb-3">
                            <h3>Do you have any questions?</h3>
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicName">
                                <Form.Label >Name</Form.Label>
                                    <Form.Control type="text" placeholder="Your Name" name="name" value={formValue.name} onChange={e=>{onChange(e)}}/>
                                    <div>{errorFormValue.name.length>0?<span style={{color:'red'}}>{errorFormValue.name}</span>:<span></span>}</div>

                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label >Email</Form.Label>

                                    <Form.Control type="email" placeholder="Your Email" name="email"  value={formValue.email}  onChange={e=>{onChange(e)}}/>
                                    <div>{errorFormValue.email.length>0?<span style={{color:'red'}}>{errorFormValue.email}</span>:<span></span>}</div>


                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPhone">
                                <Form.Label >Phone Number</Form.Label>

                                    <Form.Control type="text" placeholder="Your Phone" name="phoneNo" value={formValue.phoneNo}  onChange={e=>{onChange(e)}}/>
                                    <div>{errorFormValue.phoneNo.length>0?<span style={{color:'red'}}>{errorFormValue.phoneNo}</span>:<span></span>}</div>

  
                                </Form.Group>

                               
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label >Message</Form.Label>

                                    <Form.Control as="textarea" rows={8} placeholder="Your Message"  name="message" value={formValue.message}  onChange={e=>{onChange(e)}}/>
                                    <div>{errorFormValue.message.length>0?<span style={{color:'red'}}>{errorFormValue.message}</span>:<span></span>}</div>

                                </Form.Group>
                               
                                {
                                (formValue.name == '' && formValue.email == '' && formValue.phoneNo == '' && formValue.message == '')?
                                <Button variant="primary" type="submit" className="themebtn" disabled={true}>
                                    Submit
                                </Button>
                                :
                                <Button variant="primary" type="submit" className="themebtn" onClick={e=>{submit(e)}}>
                                    Submit
                                </Button>
                                }
                            </Form>

                        </Col>
                        <Col lg={4} className="mb-3">
                        <div class="address padding40 bg-color box-rounded mb30" >
                                <h3>Join Our Telegram Group</h3>
                                    <ul class="s1">
                                        <li> <i class="id-color fa fa-telegram fa-lg"></i><a href={officeDetail}>{officeDetail}</a></li>
                            
                                    </ul>
                                    
                                </div>

                        </Col>
                    </Row>
                </Container>

            </section>
            <Footer />
        </>
    )
}
export default Contactus