import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Header from "./directories/Header";
import Footer from "./directories/Footer";
import Modal from 'react-bootstrap/Modal';
import validator from 'validator'
import toast, { Toaster } from 'react-hot-toast';
import config from "../config/config";
import {checkUserId,newUserRegister,checkReferralId,loginuser,verifyUserName,updateUser, checkEmailExist,verifyOTP,sendEmailOTP ,updateEmailInTransaction} from "../Action/user.action"
import Cookies from "js-cookie";
import Web3 from 'web3'
import { useParams} from 'react-router-dom'

const PromoId = (props) => {
    const params = useParams();
    let userId = params

     const [form1,setForm1] = useState({userName:'',password:'',confirmPassword:'',rafferral_by:'1693816974534',walletAddress:''})
     const [formError1,setFormError1] = useState({userName:'',password:'',confirmPassword:'',rafferral_by:'',walletAddress:''})
     const [id,setId] = useState()


     useEffect(() => {
        checkUserId123()
      }, []);


      const checkUserId123=async()=>{
        let result = await checkUserId(userId)
        if(result.status){
            setId(userId.id)
        }else{
            toast.error(result.msg)
            setTimeout(() => {
                window.location.href = `${config.baseUrl}`;
        
            }, 1000);
        }
      }


const onChange1=(e)=>{
   
    setForm1({...form1,[e.target.name]:e.target.value})

}



const submit1=async(e)=>{
    e.preventDefault();
    let verify = verification1();
    if(form1.rafferral_by!=''){

        
        } 
    
    if(await verify){
        let dd= {
            userName:form1.userName,
            wallet_address:form1.walletAddress,
        }
        let verifyAPI = await verifyUserName(dd);
        if(verifyAPI.status){

            let rafferral_id = Date.now()
            
            

            let data = {
                userName:form1.userName,
                password:form1.password,
                rafferral_id:rafferral_id,
                rafferral_by:'1693816974534',
                wallet_address:form1.walletAddress,
                id:id
            }
        let updateUsers = await updateUser(data)
        if(updateUsers.status){
            toast.success(updateUsers.msg)
            let loginuserResult = await loginuser(data)
            Cookies.set("userId", loginuserResult.data);
        
            setTimeout(() => {
                window.location.href = `${config.baseUrl}`;
        
            }, 1000);
        }else{
            toast.success(updateUsers.msg)

        }

        }else{
        toast.error(verifyAPI.msg)
        }
    }else{

    }

}
const verification1=async()=>{
    const web3 = new Web3("https://kai-seeds.kardiachain.io")



    
    let userNameError = '';
    let passwordError = '';
    let confirmPasswordError = '';
    let walletAddressError = ''



    if(form1.userName == ''){
        userNameError='Invalid user name'
    }
    if(form1.password == '' ){
        passwordError = 'Invalid Password'
    }
    if(form1.password.length <6 ){
        passwordError = 'Invalid Password. Password must be greater than 6 characters'
    }
    if(form1.confirmPassword == ''){
        confirmPasswordError = 'Invalid Password'
    }
    if(form1.confirmPassword != form1.password){
        confirmPasswordError = 'Password and confirm Password does not match'
    }
    if(form1.walletAddress == ''){
        walletAddressError = 'Wallet Address needed'
    }if(!web3.utils.isAddress(form1.walletAddress)){
        walletAddressError = 'Wallet Address not valid'

    }
    

    if( userNameError == '' && passwordError == ''  && confirmPasswordError == '' && walletAddressError==''
 
    ){
        setFormError1({userName:userNameError,password:passwordError,confirmPassword:confirmPasswordError,walletAddress:walletAddressError
       
        })
        return true;
    }else{
        setFormError1({userName:userNameError,password:passwordError,confirmPassword:confirmPasswordError,walletAddress:walletAddressError
        })
        return false;
    }
}

    return (

        <>
          <Toaster />
            <Header />
            <section className='connect_wallet'>
                <Container>
                    <Row>
                        <Col md={8} xs={12} className='mx-auto py-5'>
                            
                            
                            <Form className='wallet_form mb-4' autoComplete='off' onSubmit={e=>{submit1(e)}} >
                           

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label >User Name</Form.Label>
                                    <Form.Control type="email" placeholder="Create Your User Name"  name="userName" value={form1.userName} onChange={e=>{onChange1(e)}} />
                                    {
                                    formError1.userName==''
                                    ?
                                    <span></span>
                                    :
                                    <span style={{color:'red'}}>{formError1.userName}</span>
                                    }
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label >Password</Form.Label>
                                    <Form.Control type="password" placeholder="Create A Password"  name="password" value={form1.password} onChange={e=>{onChange1(e)}} />
                                    {
                                    formError1.password==''
                                    ?
                                    <span></span>
                                    :
                                    <span style={{color:'red'}}>{formError1.password}</span>
                                    }
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label >Confirm Password</Form.Label>
                                    <Form.Control type="password" placeholder="Confirm Your Password"  name="confirmPassword" value={form1.confirmPassword} onChange={e=>{onChange1(e)}} />
                                    {
                                    formError1.confirmPassword==''
                                    ?
                                    <span></span>
                                    :
                                    <span style={{color:'red'}}>{formError1.confirmPassword}</span>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label >Wallet Address</Form.Label>
                                    <Form.Control type="text" placeholder="Your Wallet Address"  name="walletAddress" value={form1.walletAddress} onChange={e=>{onChange1(e)}} />
                                    {
                                    formError1.walletAddress==''
                                    ?
                                    <span></span>
                                    :
                                    <span style={{color:'red'}}>{formError1.walletAddress}</span>
                                    }
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label >Referral ID </Form.Label>
                                    <Form.Control type="text" placeholder="Referral Link (if you have one)"  name="rafferral_by" value={form1.rafferral_by}  />
                                    {
                                    formError1.rafferral_by==''
                                    ?
                                    <span></span>
                                    :
                                    <span style={{color:'red'}}>{formError1.rafferral_by}</span>
                                    }
                                </Form.Group>

                               
                 
                                <Button variant="primary" type='submit' className='themebtn me-3' onClick={e=>{submit1(e)}}>
                                    Submit
                                </Button>

                            </Form>
                           

                        </Col>
                    </Row>
                </Container>
            </section>

            <Footer />



           



        </>

    )
}

export default PromoId;
