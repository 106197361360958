import React, { useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Header from "./directories/Header";
import Footer from "./directories/Footer";
import Modal from 'react-bootstrap/Modal';
import validator from 'validator'
import toast, { Toaster } from 'react-hot-toast';
import config from "../config/config";
import {forgetPassword,updatePassword} from "../Action/user.action"
import Cookies from "js-cookie";


const ForgetPassword = (props) => {
    let userId = Cookies.get("userId")
    let token = Cookies.get("token")
    
    if(token){
        window.location.href = `${config.baseUrl}`;
    }
    const [show, setShow] = useState(false);
    const [form,setForm] = useState({email:''})
    const [formError,setFormError] = useState({email:''})
    const [form1,setForm1] = useState({password:'',confirmPassword:'',otp:''})
    const [formError1,setFormError1] = useState({password:'',confirmPassword:'',otp:''})
    const [otp,setOtp] = useState();
    const handleShow = () => setShow(true);
 

const onChange=(e)=>{
   
    setForm({...form,[e.target.name]:e.target.value})

}
const onChange1=(e)=>{
   
    setForm1({...form1,[e.target.name]:e.target.value})

}

const submit=async(e)=>{
    e.preventDefault();

    let verify = verification(1);
    if(verify){
        let data = {
            "email":form.email
        }
        let forgetPasswordAPI = await forgetPassword(data);
        if(forgetPasswordAPI.status){
    toast.success(forgetPasswordAPI.msg)

            setShow(true);
           
        }else{
            toast.error(forgetPasswordAPI.msg)
        }
    }else{
    }

}


const verification=(id)=>{
    
    if(id==1){
    let emialError = '';
   
    if(!(validator.isEmail(form.email)) || form.email==''){
        emialError='Invalide Email'
    }
   

    if(emialError == '' ){
        setFormError({email:emialError})
        return true;
    }else{
        setFormError({email:emialError})
        return false;
    }


}else{
    let passwordError = '';
    let confirmPasswordError = '';
    let otpError = '';


    if(form1.password == '' ){
        passwordError = 'Invalid Password'
    }
    if(form1.password.length <6 ){
        passwordError = 'Invalid Password. Password must be greater than 6 characters'
    }
    if(form1.confirmPassword == ''){
        confirmPasswordError = 'Invalid Password'
    }
   
    if(form1.confirmPassword != form1.password){
        confirmPasswordError = 'Password and confirm Password does not match'
    }
    if(form1.otp == '' || form1.otp.length<4){
        otpError = 'Invalid OTP'
    }


    if( passwordError == '' &&
    confirmPasswordError == '' &&
    otpError == ''){
        setFormError1({password:passwordError,confirmPassword:confirmPasswordError,otp:otpError})
        return true;
    }else{
        setFormError1({password:passwordError,confirmPassword:confirmPasswordError,otp:otpError})
        return false;
    }
}

}

const verifyOtp=async(e)=>{
e.preventDefault();
let verify = verification(2);
if(verify){
let data = {
    otp:form1.otp,
    email:form.email,
    password:form1.password
}
let updatePasswordAPI = await updatePassword(data)
if(updatePasswordAPI.status){
    toast.success(updatePasswordAPI.msg)

    setTimeout(() => {
        window.location.href = `${config.baseUrl}login`;

    }, 1000);
}else{
    toast.error(updatePasswordAPI.msg)

}
}
}

    return (

        <>
         <Toaster />
            <Header />
            <section className='connect_wallet mt-5 pt-5 mb-5'>
                <Container>
                    <Row>
                        <Col md={6} xs={12} className='mx-auto wallet_form'>
                        <div className='text-center mb-2'>
                                <h2>Forget Password</h2>
                            </div>
                            <Form className='p-4' autoComplete='off'>
                               
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label >Email Id</Form.Label>
                                    <Form.Control type="email" placeholder="Your Email Address"  name="email" value={form.email} onChange={e=>{onChange(e)}} />
                                    {
                                    formError.email==''
                                    ?
                                    <span></span>
                                    :
                                    <span style={{color:'red'}}>{formError.email}</span>
                                    }
                                </Form.Group>

                           
                 
                                <Button variant="primary" type='submit' className='themebtn me-3' onClick={e=>{submit(e)}}>
                                    Submit
                                </Button>

                            </Form>
                           

                        </Col>
                    </Row>
                </Container>
            </section>

            <Footer />


            {/*-------------------------- Modal Connect Wallet --------------------------- */}

            <Modal show={show}  className='verify_code' {...props}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                <Modal.Header closeButton>
                    <Modal.Title>Update Password</Modal.Title>
                    {/* <Modal
                        {...props}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    ></Modal> */}
                </Modal.Header>
                <Modal.Body className='py-0'>
                     <Form className='' autoComplete='off'>
                        <Form.Group className="mb-3">
                            <Form.Label style={{color:'black'}}>Password</Form.Label>
                            <Form.Control type="password" placeholder="New Password" name="password" value={form1.password} onChange={e=>{onChange1(e)}} />
                            {
                                    formError1.password==''
                                    ?
                                    <span></span>
                                    :
                                    <span style={{color:'red'}}>{formError1.password}</span>
                                    }
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label style={{color:'black'}}>Confirm Password</Form.Label>
                            <Form.Control type="password" placeholder="Confirm Password" name="confirmPassword" value={form1.confirmPassword} onChange={e=>{onChange1(e)}} />
                            {
                                    formError1.confirmPassword==''
                                    ?
                                    <span></span>
                                    :
                                    <span style={{color:'red'}}>{formError1.confirmPassword}</span>
                                    }
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label style={{color:'black'}}>Verify Code</Form.Label>
                            <Form.Control type="number" placeholder="Verify Code" name="otp" value={form1.otp} onChange={e=>{onChange1(e)}} />
                            {
                                    formError1.otp==''
                                    ?
                                    <span></span>
                                    :
                                    <span style={{color:'red'}}>{formError1.otp}</span>
                                    }
                        </Form.Group>
                    </Form> 

                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="primary" onClick={e=>{verifyOtp(e)}} className='themebtn mx-auto'>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>



        </>

    )
}

export default ForgetPassword;
