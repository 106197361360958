import React, { useState } from 'react';
import { Container, Row, Col, Button, Tab, Nav, Table } from 'react-bootstrap';
import config from '../config/config';
import Header from "./directories/Header";
import Footer from "./directories/Footer";
import "../componets/myprofile.css"
import { useEffect } from "react";
import { getUserById, getUserTransaction } from "../Action/user.action"
import ReactDatatable from '@ashvin27/react-datatable'
import Cookies from "js-cookie";

const Myprofile = () => {

    let userId = Cookies.get("userId")
    let token = Cookies.get("token")

    if(!(token)){
        window.location.href = `${config.baseUrl}`;
    }


   

    const [data, setdata] = useState({})
    const [transactions, settransactions] = useState([])

    useEffect(() => {
        userDetails();
        userTransactions();
    }, [])
    const userDetails = async () => {
        let userD = await getUserById({ "userId": userId });
      
        setdata(userD.data)
    }
    const userTransactions = async () => {
        let userD = await getUserTransaction({ "userId": userId });
      
        settransactions(userD.data)
    }


    return (
        <>
            <Header />
            <section className='myactivity' style={{minHeight: "500px"}}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className='profile_content py-4 pb-5'>
                                
                                {(data.length>0)?
                             <>
                               <div className='dappicon'>
                                     <a href={`${config.baseUrl}setting`}>
                                        
                                       {
                                            data.profile_pic != '' && data.profile_pic != null ?
                                                <img src={`${config.imageURL}` + `${data[0].profile_pic}`} loading="lazy" className='lazybox' />
                                                :
                                                <img src="images/imgpsh_fullsize_anim1.png" loading="lazy" className='lazybox' />

                                        }
                                    </a>
                                </div>
                                <div className='profile-text ms-3'>
                                    <h4>{data[0].userName} </h4>
                                    <a>{data[0].email}</a>
                                    <p>{data[0].timestamp}</p>
                                </div></> 
                                 :
                                <></>
} 
                            </div>
                        </Col>
                    </Row>

                    <Row className="d-flex justify-content-center align-items-center">
                        <Col md={12}>
                            <div className="card-sections">
                                <div className="main-section">
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="livenow" >
                                        <Row>
                                            <Col sm={12} className="" >
                                                <Nav variant="pills" className="border-bottom border-dark pt-2 mb-4" >
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="livenow" className='text-white fs-5 pl-0'>Competitions Entered</Nav.Link>
                                                    </Nav.Item>

                                                    {/* <Nav.Item>
                                                        <Nav.Link eventKey="activity">NFTs Owned</Nav.Link>
                                                    </Nav.Item> */}
                                                </Nav>
                                            </Col>
                                            <Col sm={12}>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="livenow">
                                                        <div className="box-body">

                                                            {/* <ReactDatatable
                                                                config={configForTable}
                                                                records={transactions}
                                                                columns={columns}
                                                            /> */}
                                                            <Table striped hover className="mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Sr No.</th>
                                                                        <th>Raffle No.</th>
                                                                        <th>Transaction Hash</th>
                                                                        <th>Tickets</th>
                                                                        <th>Kai</th>
                                                                        <th>Ticket Number</th>

                                                                        
                                                                        <th className="text-right">Time</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {transactions.length == 0 ?
                                                                        <tr className='text-center'>
                                                                            <th colSpan="7" className="border-0 pt-3 pb-3">No Data Found</th>

                                                                        </tr>
                                                                        :

                                                                        transactions.map((item, i) => (
                                                                            <tr>
                                                                                <td>
                                                                                    <div className="activity-name">
                                                                                        <a href="javascript:void();" className="d-flex align-items-center">
                                                                                            <span>{i + 1}
                                                                                                {/* <img src="images/default.png" /> */}
                                                                                            </span>
                                                                                        </a>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="activity-name">
                                                                                        <a href="javascript:void();" className="d-flex align-items-center">
                                                                                            <span>{item.raffle_count}
                                                                                                {/* <img src="images/default.png" /> */}
                                                                                            </span>
                                                                                        </a>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="activity-name">
                                                                                        <a href="javascript:void();" className="d-flex align-items-center">
                                                                                            <h3 className="block">{item.hash} </h3>
                                                                                        </a>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="activity-name">
                                                                                        <a href="javascript:void();" className="d-flex align-items-center">
                                                                                            <h3 className="block">{item.ticket_buy} </h3>
                                                                                        </a>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="activity-name">
                                                                                        <a href="javascript:void();" className="d-flex align-items-center">
                                                                                            <h3 className="block">{item.kia_used} </h3>
                                                                                        </a>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="activity-name">
                                                                                        <a href="javascript:void();" className="d-flex align-items-center">
                                                                                            <h3 className="block">{item.ticketNumber} </h3>
                                                                                        </a>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="text-right"><div><a >{item.timestamp} <i className="fa fa-edit"></i></a></div></td>
                                                                            </tr>))


                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </div>                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="activity">
                                                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here,</p>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </div>

                            </div>

                        </Col>


                    </Row>
                </Container>
            </section>

            <Footer />

        </>
    )
}

export default Myprofile;
