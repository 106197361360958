import React, { Component, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import config from '../config/config';

import Home from '../componets/home';
import Contactus from '../componets/contactus';
import TicketDetails from '../componets/ticketDetails';
import ConnectWallet from '../componets/connectWallet';
import Myprofile from '../componets/myprofile';
import Setting from '../componets/setting';
import Login from '../componets/login';
import ForgetPassword from '../componets/forgetPassword';
import Promo from '../componets/promo';
import PromoId from '../componets/promoId';
import SpinTheWheel from '../componets/spinthewheel';
import Buykai from '../componets/buykai';
const RouterComponent = () => {
    return (<BrowserRouter >
            <div> 
                <Routes>
                    <Route path={`${config.baseUrl}`} element={<Home/>} />
                    <Route path={`${config.baseUrl}contactus`} element={<Contactus/>} />
                    <Route path={`${config.baseUrl}ticket-details`} element={<TicketDetails/>} />
                    <Route path={`${config.baseUrl}connectWallet`} element={<ConnectWallet/>} />
                    <Route path={`${config.baseUrl}myprofile`} element={<Myprofile/>} />
                    <Route path={`${config.baseUrl}setting`} element={<Setting/>} />
                    <Route path={`${config.baseUrl}login`} element={<Login/>} />
                    <Route path={`${config.baseUrl}forgetPassword`} element={<ForgetPassword/>} />
                    <Route path={`${config.baseUrl}promo`} element={<Promo/>} />
                    <Route path={`${config.baseUrl}promo/:id`} element={<PromoId/>} />
                    <Route path={`${config.baseUrl}spinTheWheel`} element={<SpinTheWheel/>} />
                    <Route path={`${config.baseUrl}buykai`} element={<Buykai/>} />


                </Routes>
            </div>
    </BrowserRouter>
    )
}
export default RouterComponent