import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Breadcrumb, Tab, Modal, Form } from 'react-bootstrap';
import Header from "./directories/Header";
import Footer from "./directories/Footer";
import { Link } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import { Fade, Zoom } from 'react-reveal';
import { useSelector } from 'react-redux';
import config from "../config/config";
import RaffleManagementABI from '../config/RaffleManagementABI.json'
import Web3 from '/node_modules/web3/dist/web3.min.js';
import toast, { Toaster } from 'react-hot-toast';
import validator from 'validator'
import commaNumber from 'comma-number'
import Cookies from "js-cookie";
import BigNumber from "bignumber.js"

import { getUserById, getTransactionDetailsById, checkActiveAccount, sendWinnerMail, updateEmailInTransaction, endRaflle, getTransactionDetailsByIndexNumber, discreption, getTotalSoldTickets, insertTransaction, getAllTransactionDetail, getActivity, getTotalKai, sendMailReq } from "../Action/user.action"
const TicketEntry = [
    {
        entry: "1,000",
        price: "KAI 1000"
    },
    {
        entry: "10,000",
        price: "KAI 10,000"
    },
    {
        entry: "100,000",
        price: "KAI 1,00,000"
    },


]
const TicketDetails = () => {



    let userId = Cookies.get("userId")

    const [wrongChainTrue, setWrongChainTrue] = useState()
    const [account, setAccount] = useState()
    const [totalWinningAmount, setTotalWinningAmount] = useState()
    const [activity, setActivity] = useState([])
    const [participants, setParticipants] = useState([])
    const [totalKia, setTotalKia] = useState();
    const [discreptions, setDiscreption] = useState([]);

    const [remainingTickets, setRemainingtickets] = useState()

    const [value1, setValue1] = useState('1')
    const [value2, setValue2] = useState('1')
    const [value3, setValue3] = useState('1')


    const [offers1, setOffers1] = useState([{
        offerPrice: '',
        offerTicket: '',
        offerBounecTicket: ''
    }])
    const [offers2, setOffers2] = useState([{
        offerPrice: '',
        offerTicket: '',
        offerBounecTicket: ''
    }])
    const [offers3, setOffers3] = useState([{
        offerPrice: '',
        offerTicket: '',
        offerBounecTicket: ''
    }])


    const [loader, setLoader] = useState(false)

    const [bonus1, setBonus1] = useState(0)
    const [bonus2, setBonus2] = useState(1000)
    const [bonus3, setBonus3] = useState(20000)

    const [offer1Error, setOffer1Error] = useState(false)
    const [offer2Error, setOffer2Error] = useState(false)
    const [offer3Error, setOffer3Error] = useState(false)


    const [offer1ErrorMax, setOffer1ErrorMax] = useState(false)
    const [offer2ErrorMax, setOffer2ErrorMax] = useState(false)
    const [offer3ErrorMax, setOffer3ErrorMax] = useState(false)

    const [totalTicket1, setTotalTicket1] = useState(1000)
    const [totalTicket2, setTotalTicket2] = useState(11000)
    const [totalTicket3, setTotalTicket3] = useState(120000)


    // const [totalKai1, setTotalKai1] = useState(0.0001)
    // const [totalKai2, setTotalKai2] = useState(0.001)
    // const [totalKai3, setTotalKai3] = useState(0.01)

    const [totalKai1, setTotalKai1] = useState(1000)
    const [totalKai2, setTotalKai2] = useState(10000)
    const [totalKai3, setTotalKai3] = useState(100000)


    const [totalTicket, setTotalTicket] = useState()
    const [totalPrice, setTotalPrice] = useState()
    const [email, setEmail] = useState()


    const [isActive, setIsActive] = useState()

    const [insertedId, setInsertId] = useState()
    //Buys Tickets Function Defination
    const [userPresent, setUserPresent] = useState(false)

    useEffect(() => {
        //if(userId){
        activeUser()
        //}


        getTT()
        getTotalKais()

        getDiscreption();



        // setInterval(() => {
        checkWalletConnect();
        getActive()

        // }, 1000)


    }, []);







    const checkWalletConnect = async () => {
        if (userId == undefined) {

            setUserPresent(true)
        } else {
            setUserPresent(false)

        }

    }

    const connectMetaMask = async (e, chainId) => {


        toast.error("Please Login")

    }

    const getTT = async () => {
        let getAllTransactionDetails = await getAllTransactionDetail();


        setParticipants(getAllTransactionDetails.data)
    }





    const activeUser = async (id) => {
        let getActivitys = await getActivity()

        if (getActivitys.data.length > 0) {
            getActivitys.data.map((item) => {
                item.ticket = (item.ticketNumber.split('-'))
            })

            setActivity(getActivitys.data)
        }


    }



    // --------
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [emailError, setEmailError] = useState(true)

    const [newshow1, setNewShow1] = useState(false);
    const [newshow2, setNewShow2] = useState(false);
    const [newshow3, setNewShow3] = useState(false);


    const [newshow4, setNewShow4] = useState(false);
    const [newshow5, setNewShow5] = useState(false);
    const [newshow6, setNewShow6] = useState(false);

    const [transactionHash, setTransactionHash] = useState('');
    const handleClose1 = () => {
        window.location.reload(true)
    }
    const handleClose = () => {

        setShow1(false);
        setShow2(false);
        setShow3(false);
        setNewShow1(false);
        setNewShow2(false);
        setNewShow3(false);
        setOffer1Error(false)
        setOffer2Error(false)
        setOffer3Error(false)
        setTotalTicket1(1000)
        setTotalTicket2(11000)
        setTotalTicket3(120000)
        // setTotalKai1(0.0001)
        // setTotalKai2(0.001)
        // setTotalKai3(0.01)
        setTotalKai1(1000)
        setTotalKai2(10000)
        setTotalKai3(100000)
        setBonus1(0)
        setBonus2(1000)
        setBonus3(20000)
        setValue1('1');
        setValue2('1');
        setValue3('1');
    }
    const handleShow1 = async () => {


        if (!(window.ethereum) && !(window.kardiachain.isKaiWallet)) {
            toast.error("Please install metamask")
            return
        }
        //const web3 = new Web3(window.ethereum);


        let web3;

        if (window.kardiachain) {
            web3 = new Web3(window.kardiachain);
            if (window.kardiachain.isKaiWallet) {
                window.kardiachain.enable();
            }

        } else if (window.ethereum) {
            web3 = new Web3(window.ethereum);

        }

        const currentChainId = await web3.eth.net.getId();

        if (currentChainId !== config.chainId) {
            try {
                const data = await web3.currentProvider.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: Web3.utils.toHex(config.chainId) }],
                });
                setShow1(true)

            } catch (switchError) {
                if (switchError.code === 4902) {
                    toast.error("Please connect with Kardichain network");
                    return false;
                }
            }
        } else {
            setShow1(true)
        }

    };
    const handleShow2 = async () => {

        if (!(window.ethereum) && !(window.kardiachain.isKaiWallet)) {
            toast.error("Please install metamask")
            return
        }
        //const web3 = new Web3(window.ethereum);


        let web3;

        if (window.kardiachain) {
            web3 = new Web3(window.kardiachain);
            if (window.kardiachain.isKaiWallet) {
                window.kardiachain.enable();
            }

        } else if (window.ethereum) {
            web3 = new Web3(window.ethereum);

        }


        const currentChainId = await web3.eth.net.getId();

        if (currentChainId !== config.chainId) {
            try {
                const data = await web3.currentProvider.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: Web3.utils.toHex(config.chainId) }],
                });
                setShow2(true);

            } catch (switchError) {
                if (switchError.code === 4902) {
                    toast.error("Please connect with Kardichain network");
                    return false;
                }
            }
        } else {
            setShow2(true);
        }

    }
    const handleShow3 = async () => {
        if (!(window.ethereum) && !(window.kardiachain.isKaiWallet)) {
            toast.error("Please install metamask")
            return
        }
        //  const web3 = new Web3(window.ethereum);


        let web3;

        if (window.kardiachain) {
            web3 = new Web3(window.kardiachain);
            if (window.kardiachain.isKaiWallet) {
                window.kardiachain.enable();
            }

        } else if (window.ethereum) {
            web3 = new Web3(window.ethereum);

        }



        const currentChainId = await web3.eth.net.getId();

        if (currentChainId !== config.chainId) {
            try {
                const data = await web3.currentProvider.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: Web3.utils.toHex(config.chainId) }],
                });
                setShow3(true);

            } catch (switchError) {
                if (switchError.code === 4902) {
                    toast.error("Please connect with Kardichain network");
                    return false;
                }
            }
        } else {
            setShow3(true);
        }

    }

    const handleShow4 = () => setNewShow1(true);
    const handleShow5 = () => setNewShow2(true);
    const handleShow6 = () => setNewShow3(true);
    // -------

    const registerAccount = (e) => {
        e.preventDefault();
        localStorage.setItem("insertedId", insertedId);
        window.location.href = `${config.baseUrl}connectWallet`;
    }

    const showGuest = (e, index) => {
        e.preventDefault();

        if (index == '1') {
            setNewShow1(true)
        } else if (index == '2') {
            setNewShow2(true)
        } else if (index == '3') {
            setNewShow3(true)
        }
    }

    const getTotalKais = async () => {
        let result = await getTotalKai()
        setTotalKia(result.data);
    }

    const getDiscreption = async () => {
        let result = await discreption();

        setDiscreption(result.data[0].content);
    }


    const handleChange1 = async (e) => {
        setOffer1Error(false)
        setOffer1ErrorMax(false)

        if (e.target.value > 0) {
            if (e.target.value > 9) {
                setOffer1Error(true)
                setValue1(e.target.value);
            } else {
                if (totalWinningAmount - totalKia >= offers1[0].offerPrice * e.target.value) {
                    setValue1(e.target.value);
                    setTotalTicket1(parseInt(parseInt(offers1[0].offerTicket) * parseInt(e.target.value)) + parseInt(parseInt(offers1[0].offerBounecTicket) * parseInt(e.target.value)))
                    setTotalKai1(offers1[0].offerPrice * e.target.value);
                    setBonus1(offers1[0].offerBounecTicket * e.target.value)
                } else {
                    setValue1(e.target.value);
                    setOffer1ErrorMax(true)

                }
            }

        } else {
            setValue1('');
            setTotalTicket1(0);
            setTotalKai1(0);
            setBonus1(0)
        }
    }
    const handleChange2 = (e) => {
        setOffer2Error(false)
        setOffer2ErrorMax(false)

        if (e.target.value > 0) {
            if (e.target.value > 9) {
                setOffer2Error(true)
                setValue2(e.target.value);
            } else {
                if (totalWinningAmount - totalKia >= offers2[0].offerPrice * e.target.value) {
                    setValue2(e.target.value);
                    setTotalTicket2(parseInt(parseInt(offers2[0].offerTicket) * parseInt(e.target.value)) + parseInt(parseInt(offers2[0].offerBounecTicket) * parseInt(e.target.value)))
                    setTotalKai2(offers2[0].offerPrice * e.target.value)
                    setBonus2(offers2[0].offerBounecTicket * e.target.value)
                } else {
                    setOffer2ErrorMax(true)
                }
            }

        } else {
            setValue2('');
            setTotalTicket2(0)
            setBonus2(0)
        }
    }
    const handleChange3 = (e) => {
        setOffer3Error(false)
        setOffer3ErrorMax(false)
        if (e.target.value > 0) {
            if (e.target.value > 12) {
                setOffer3Error(true)
                setValue3(e.target.value);
            } else {
                if (totalWinningAmount - totalKia >= offers3[0].offerPrice * e.target.value) {
                    setValue3(e.target.value);
                    //setTotalTicket3(parseInt(parseInt(offers3[0].offerTicket) * parseInt(e.target.value)) + parseInt(parseInt(offers3[0].offerBounecTicket) * parseInt(e.target.value)))
                    setTotalTicket3(parseInt(parseFloat((offers3[0].offerTicket)).toFixed(1)) * parseInt(e.target.value) + parseInt(parseFloat((offers3[0].offerBounecTicket)).toFixed(1)) * parseInt(e.target.value))

                    setTotalKai3(parseInt(parseFloat((offers3[0].offerPrice)).toFixed(1)) * parseInt(e.target.value))
                    setBonus3(parseInt(parseFloat((offers3[0].offerBounecTicket)).toFixed(1)) * parseInt(e.target.value))

                    //console.log(parseInt(parseFloat((offers3[0].offerTicket)).toFixed(1)) *parseInt(e.target.value) +parseInt(parseFloat((offers3[0].offerBounecTicket)).toFixed(1)) * parseInt(e.target.value) )
                    //console.log(parseInt(parseFloat((offers3[0].offerBounecTicket)).toFixed(1)) * parseInt(e.target.value))
                } else {
                    setOffer3ErrorMax(true)
                }
            }

        } else {
            setValue3('');
            setTotalTicket3(0);
            setTotalKai3(0);
            setBonus3(0)
        }
    }

    const handleEmailChange = (e) => {

        var email = e.target.value


        if (validator.isEmail(email)) {
            setEmailError(false)
            setEmail(e.target.value)
        } else {
            setEmailError(true)
        }

    }

    const sendMail = async (e) => {
        setLoader(false)
        e.preventDefault();
        let result = await getTransactionDetailsById({ id: insertedId })

        let data1 = {
            account: result.data[0].user_address,
            totalPrice: result.data[0].kia_used,
            totalTicket: result.data[0].ticket_buy,
            transactionHash: result.data[0].hash,
            email: email,
            ticketnumber: result.data[0].ticketNumber
        };
        let result1 = await sendMailReq(data1);
        if (result1.status) {
            let data = {
                email: email,
                id: insertedId
            }
            let emailUpdate = await updateEmailInTransaction(data);

            toast.success(`Mail Successfully sent on  ${email} `)

            setTimeout(() => {
                window.location.reload();

            }, 1000);

        }
    }


    const sendMailDirectly = async (data) => {
        let result = await getTransactionDetailsById({ id: data.insertedID })
        let data1 = {
            account: result.data[0].user_address,
            totalPrice: result.data[0].kia_used,
            totalTicket: result.data[0].ticket_buy,
            transactionHash: result.data[0].hash,
            email: data.email,
            ticketnumber: result.data[0].ticketNumber
        };
        let result1 = await sendMailReq(data1);
        let data12 = {
            email: data.email,
            id: data.insertedID
        }
        let emailUpdate = await updateEmailInTransaction(data12);

        toast.success(`Mail Successfully sent on  ${data.email} `)


        if (data.transaction.logs.length > 1) {


            await announceWinner(data.transaction)

        }



        // setTimeout(() => {
        //     window.location.reload();

        // }, 1000);
    }

    const buyTickets = async (e, index, count) => {
        e.preventDefault();
        setLoader(true)




        let web3;
        let accounts;
        if (window.kardiachain) {

            web3 = new Web3(window.kardiachain);
            //  if (window.kardiachain.isKaiWallet) {
            //     window.kardiachain.enable();
            // }

            accounts = await web3.eth.getAccounts()
            accounts = accounts[0]
            let accounts1 = await window.kardiachain.selectedAddress


            let currentAccount = accounts
            let balance = await web3.eth.getBalance(currentAccount)


            balance = balance / 10 ** 18;

            let RaffleManagementMethods = new web3.eth.Contract(RaffleManagementABI, config.RaffleManagement);

            let offers = await RaffleManagementMethods.methods.Offers(index).call();
            let price = parseInt(offers.price)

           // price = parseInt(parseFloat(price / 10 ** 18).toFixed(1)) * count;
           price = (parseFloat(price / 10 ** 18)) * count;
           

            let totalTickets = parseInt(offers.totalTickets * count)
            setTotalPrice(price)
            let value = price * 10 ** 18

            value = (BigNumber(value))
       
            if (price < balance) {
                if (accounts) {

                    setLoader(true)


                    try {


                        let buyTickets = await RaffleManagementMethods.methods.buyTickets(index, count);

                        let encoded_tx = buyTickets.encodeABI();


                        let gasPrice = await web3.eth.getGasPrice();

                        let gasLimit = await web3.eth.estimateGas({
                            gasPrice: web3.utils.toHex(gasPrice),
                            to: config.RaffleManagement,
                            from: accounts,
                            data: encoded_tx,
                            value: value
                        });

                        let trx = await web3.eth.sendTransaction({
                            gasPrice: web3.utils.toHex(gasPrice),
                            gas: web3.utils.toHex(gasLimit),
                            to: config.RaffleManagement,
                            from: accounts,
                            data: encoded_tx,
                            value: value
                        }

                        )






                        if (trx.transactionHash) {



                            let data1 = {
                                raffle_id: index,
                                offer_id: index,
                                user_id: userId,
                                user_address: accounts,
                                transaction_status: 1,
                                hash: trx.transactionHash,
                                ticket_buy: totalTickets,
                                kia_used: price,
                                raffle_completed: 0,
                                ticketNumber: totalTickets,
                                count: count

                            }
                            let result = await insertTransaction({ data: data1 })


                            if (result.status) {

                                setInsertId(result.data);



                                toast.success(`Transaction Successful ${trx.transactionHash.toString().substring(0, 3)}...${trx.transactionHash.toString().substring(trx.transactionHash.length - 3, trx.transactionHash.length)} `)
                                // toast.success(`Transaction Successful  `)

                                let isAccountActive = await getUserById({ "userId": userId })

                                if (isAccountActive.status) {
                                    let data = {

                                        email: isAccountActive.data[0].email,

                                        insertedID: result.data,

                                        transaction: trx

                                    }
                                    sendMailDirectly(data)
                                }

                                if (index == '1') {
                                    setNewShow1(true)
                                } else if (index == '2') {
                                    setNewShow2(true)
                                } else if (index == '3') {
                                    setNewShow3(true)
                                }

                            }
                        }

                    }
                    catch (e) {


                        toast.error(e.message)
                        setTimeout(() => {
                            setLoader(false)

                            window.location.reload();
                        }, 1000);
                    }
                }

            } else {
                toast.error(`Insufficient Balance `);
                setLoader(false)
            }


        } else if (window.ethereum) {
            web3 = new Web3(window.ethereum);
            accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            accounts = accounts[0]

            const currentChainId = await web3.eth.net.getId();

            if (currentChainId !== config.chainId) {
                try {
                    const data = await web3.currentProvider.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: Web3.utils.toHex(config.chainId) }],
                    });


                } catch (switchError) {
                    if (switchError.code === 4902) {
                        toast.error("Please connect with Kardichain network");
                        return false;
                    }
                }
            }


            let currentAccount = accounts
            let balance = await web3.eth.getBalance(currentAccount)


            balance = balance / 10 ** 18;

            let RaffleManagementMethods = new web3.eth.Contract(RaffleManagementABI, config.RaffleManagement);

            let offers = await RaffleManagementMethods.methods.Offers(index).call();
            let price = parseInt(offers.price)

           // price = parseInt(parseFloat(price / 10 ** 18).toFixed(1)) * count;
           price = (parseFloat(price / 10 ** 18)) * count;
            let totalTickets = parseInt(offers.totalTickets * count)
            setTotalPrice(price)
            let value = price * 10 ** 18

            value = (BigNumber(value))
           
            if (price < balance) {
                if (accounts) {

                    setLoader(true)


                    try {


                        let buyTickets = await RaffleManagementMethods.methods.buyTickets(index, count);

                        let encoded_tx = buyTickets.encodeABI();


                        let gasPrice = await web3.eth.getGasPrice();

                        let gasLimit = await web3.eth.estimateGas({
                            gasPrice: web3.utils.toHex(gasPrice),
                            to: config.RaffleManagement,
                            from: accounts,
                            data: encoded_tx,
                            value: value
                        });

                        let trx = await web3.eth.sendTransaction({
                            gasPrice: web3.utils.toHex(gasPrice),
                            gas: web3.utils.toHex(gasLimit),
                            to: config.RaffleManagement,
                            from: accounts,
                            data: encoded_tx,
                            value: value
                        }

                        )






                        if (trx.transactionHash) {



                            let data1 = {
                                raffle_id: index,
                                offer_id: index,
                                user_id: userId,
                                user_address: accounts,
                                transaction_status: 1,
                                hash: trx.transactionHash,
                                ticket_buy: totalTickets,
                                kia_used: price,
                                raffle_completed: 0,
                                ticketNumber: totalTickets,
                                count: count

                            }
                            let result = await insertTransaction({ data: data1 })

                            if (result.status) {

                                setInsertId(result.data);



                                toast.success(`Transaction Successful ${trx.transactionHash.toString().substring(0, 3)}...${trx.transactionHash.toString().substring(trx.transactionHash.length - 3, trx.transactionHash.length)} `)
                                // toast.success(`Transaction Successful  `)

                                let isAccountActive = await getUserById({ "userId": userId })

                                if (isAccountActive.status) {
                                    let data = {

                                        email: isAccountActive.data[0].email,

                                        insertedID: result.data,

                                        transaction: trx

                                    }
                                    sendMailDirectly(data)
                                }

                                if (index == '1') {
                                    setNewShow1(true)
                                } else if (index == '2') {
                                    setNewShow2(true)
                                } else if (index == '3') {
                                    setNewShow3(true)
                                }

                            }
                        }

                    }
                    catch (e) {


                        toast.error(e.message)
                        setTimeout(() => {
                            setLoader(false)

                            window.location.reload();
                        }, 1000);
                    }
                }

            } else {
                toast.error(`Insufficient Balance `);
                setLoader(false)
            }


        }



    }


    const announceWinner = async (trx) => {

        const web3 = new Web3(window.ethereum);


        let abi1 = [
            {
                "indexed": false,
                "internalType": "address",
                "name": "WinnerAddress",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "winnerAmount",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "winnerId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "winningTicket",
                "type": "uint256"
            }
        ]

        let logdecode = await web3.eth.abi.decodeLog(abi1, trx.logs[1].data, trx.logs[1].topics[0]);

        let getWinnerDetails = await getTransactionDetailsByIndexNumber({ ticketsHolderIndex: logdecode.winnerId })
        let data = {
            raffle_id: getWinnerDetails.data.raffle_id,
            offer_id: getWinnerDetails.data.offer_id,
            user_id: getWinnerDetails.data.user_id,
            user_address: logdecode.WinnerAddress,
            transaction_status: 2,
            hash: "winner",
            ticket_buy: logdecode.winningTicket,
            kia_used: logdecode.winnerAmount / 10 ** 18,
            raffle_completed: 1,
            email: getWinnerDetails.data.email,
            ticketNumber: getWinnerDetails.data.ticketNumber,
            ticketsHolderIndex: getWinnerDetails.data.ticketsHolderIndex
        }
        let winnerInserted = await insertTransaction({ data: data })
        let mlm = {
            account: logdecode.WinnerAddress,
            totalPrice: logdecode.winnerAmount,
            raffleNumber: getWinnerDetails.data.raffle_count,
            email: getWinnerDetails.data.email,
            ticketnumber: logdecode.winningTicket
        };
        let result = await sendWinnerMail(mlm);


        if (winnerInserted.status) {



            let res = await endRaflle()



        }

    }


    const getActive = async () => {

        let web3;

        if (window.kardiachain) {
            web3 = new Web3(window.kardiachain);
            if (window.kardiachain.isKaiWallet) {
                window.kardiachain.enable();
            }
            const accounts = await window.kardiachain.selectedAddress
            setAccount(accounts)
            const currentChainId = await web3.eth.net.getId();

            if (currentChainId != config.chainId) {
                setWrongChainTrue(true)
            }
            let RaffleManagementMethods = new web3.eth.Contract(RaffleManagementABI, config.RaffleManagement);

            let RaffleActive = await RaffleManagementMethods.methods.RaffleActive().call();


            setIsActive(RaffleActive)


            let winnerAmount = await RaffleManagementMethods.methods.winnerAmount().call();

            winnerAmount = winnerAmount / 10 ** 18;
            let ownerAmount = await RaffleManagementMethods.methods.ownerAmount().call();

            ownerAmount = ownerAmount / 10 ** 18;

            let RaffleDetals = await RaffleManagementMethods.methods.Raffle(1).call();

            let TotalAmount = RaffleDetals.TotalAmount / 10 ** 18;


            let winningAmount = (winnerAmount + ownerAmount)






            let remainingTickets = winningAmount - TotalAmount
            setRemainingtickets(parseFloat(remainingTickets))

            setTotalWinningAmount(parseFloat(winningAmount))
            //Disable started
            let offers1 = await RaffleManagementMethods.methods.Offers("1").call();
            let offers2 = await RaffleManagementMethods.methods.Offers("2").call();
            let offers3 = await RaffleManagementMethods.methods.Offers("3").call();

            let offers1price = offers1.price / 10 ** 18;
            let offers2price = offers2.price / 10 ** 18;
            let offers3price = offers3.price / 10 ** 18;

            let offers1totalTickets = offers1.totalTickets;

            let offers2totalTickets = offers2.totalTickets;

            let offers3totalTickets = offers3.totalTickets;

            // let offers1bounousTicket = offers1totalTickets - offers1.price / 10 ** 11;//10**18
            // let offers2bounousTicket = offers2totalTickets - offers2.price / 10 ** 11;//10**18
            // let offers3bounousTicket = offers3totalTickets - offers3.price / 10 ** 11;//10**18
            let offers1bounousTicket = offers1totalTickets - offers1.price / 10 ** 18
            let offers2bounousTicket = offers2totalTickets - offers2.price / 10 ** 18
            let offers3bounousTicket = offers3totalTickets - offers3.price / 10 ** 18


            setOffers1([{
                offerPrice: offers1price,
                offerTicket: offers1totalTickets - offers1bounousTicket,
                offerBounecTicket: offers1bounousTicket
            }])
            setOffers2([{
                offerPrice: offers2price,
                offerTicket: offers2totalTickets - offers2bounousTicket,
                offerBounecTicket: offers2bounousTicket
            }])
            setOffers3([{
                offerPrice: offers3price,
                offerTicket: offers3totalTickets - offers3bounousTicket,
                offerBounecTicket: offers3bounousTicket
            }])

        } else {
            web3 = new Web3(window.ethereum);
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setAccount(accounts[0])

            const currentChainId = await web3.eth.net.getId();
            if (currentChainId != config.chainId) {
                setWrongChainTrue(true)
            }
            let RaffleManagementMethods = new web3.eth.Contract(RaffleManagementABI, config.RaffleManagement);

            let RaffleActive = await RaffleManagementMethods.methods.RaffleActive().call();


            setIsActive(RaffleActive)


            let winnerAmount = await RaffleManagementMethods.methods.winnerAmount().call();

            winnerAmount = winnerAmount / 10 ** 18;
            let ownerAmount = await RaffleManagementMethods.methods.ownerAmount().call();

            ownerAmount = ownerAmount / 10 ** 18;

            let RaffleDetals = await RaffleManagementMethods.methods.Raffle(1).call();

            let TotalAmount = RaffleDetals.TotalAmount / 10 ** 18;


            let winningAmount = (winnerAmount + ownerAmount)






            let remainingTickets = winningAmount - TotalAmount
            setRemainingtickets(parseFloat(remainingTickets))

            setTotalWinningAmount(parseFloat(winningAmount))
            //Disable started
            let offers1 = await RaffleManagementMethods.methods.Offers("1").call();
            let offers2 = await RaffleManagementMethods.methods.Offers("2").call();
            let offers3 = await RaffleManagementMethods.methods.Offers("3").call();

            let offers1price = offers1.price / 10 ** 18;
            let offers2price = offers2.price / 10 ** 18;
            let offers3price = offers3.price / 10 ** 18;

            let offers1totalTickets = offers1.totalTickets;

            let offers2totalTickets = offers2.totalTickets;

            let offers3totalTickets = offers3.totalTickets;

            // let offers1bounousTicket = offers1totalTickets - offers1.price / 10 ** 11;//10**18
            // let offers2bounousTicket = offers2totalTickets - offers2.price / 10 ** 11;//10**18
            // let offers3bounousTicket = offers3totalTickets - offers3.price / 10 ** 11;//10**18
            let offers1bounousTicket = offers1totalTickets - offers1.price / 10 ** 18
            let offers2bounousTicket = offers2totalTickets - offers2.price / 10 ** 18
            let offers3bounousTicket = offers3totalTickets - offers3.price / 10 ** 18


            setOffers1([{
                offerPrice: offers1price,
                offerTicket: offers1totalTickets - offers1bounousTicket,
                offerBounecTicket: offers1bounousTicket
            }])
            setOffers2([{
                offerPrice: offers2price,
                offerTicket: offers2totalTickets - offers2bounousTicket,
                offerBounecTicket: offers2bounousTicket
            }])
            setOffers3([{
                offerPrice: offers3price,
                offerTicket: offers3totalTickets - offers3bounousTicket,
                offerBounecTicket: offers3bounousTicket
            }])

        }





    }



    return (
        <>
            <Header />
            <Toaster />

            <section>




                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="ticket-details">

                                <Row className="">
                                    <Col md={4} className="mt-4">
                                        <div className="description-section">
                                            <div className="detail-image">
                                                <img src="images/imgpsh_fullsize_anim1.png" />
                                                <h3>DESCRIPTION</h3>
                                                <hr />
                                                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. </p> */}
                                                <div style={{ lineHeight: '22px' }}
                                                    dangerouslySetInnerHTML={{ __html: discreptions }}
                                                />
                                            </div>
                                            {/* <div className="form-inner">
                                                <div style={{ lineHeight: '22px' }}
                                                    dangerouslySetInnerHTML={{ __html: discreptions }}
                                                />
                                            </div> */}

                                        </div>

                                    </Col>
                                    <Col md={8} className="mt-4">
                                        <div className="details-name">
                                            <div className="ticket-name">
                                                <h2>KAI RAFFLE</h2><img src="images/check.png" />
                                            </div>
                                            <div className="price-detail">
                                                <h3>#25269</h3>
                                                {/* <h3><span>Value : </span>$49,152</h3> */}
                                            </div>
                                            <hr />
                                            <div className="card-area">
                                                {remainingTickets ?
                                                    <h4>REMAINING TICKETS {commaNumber(remainingTickets)}</h4>
                                                    :
                                                    wrongChainTrue ?
                                                        <h4>REMAINING TICKETS <span style={{ color: 'red' }}>Please select Kardiachain Network</span></h4>
                                                        :
                                                        <h4>REMAINING TICKETS</h4>
                                                }
                                                <div className="selected-cards" style={{ minHeight: "470px" }}>
                                                    <div className="text-center">
                                                        <h3 className="mb-3">ENTER RAFFLE</h3>
                                                    </div>
                                                    <div className="connect-btn mb-4">
                                                        {wrongChainTrue ?
                                                            <Button className="border-btn px-4">
                                                                Raffle Started
                                                            </Button>
                                                            :
                                                            isActive ? <Button className="border-btn px-4">
                                                                Raffle Started
                                                            </Button> : <Button className="border-btn px-4">
                                                                Raffle Not Started
                                                            </Button>}
                                                    </div>
                                                    <Row>


                                                        <Col lg={4} mb={6} className='mb-3'>
                                                            <div className="btn-gradient-1">
                                                                <div className="entry-ticket pt-3">
                                                                    <span className="uppercase">&nbsp;</span>
                                                                    <h1>1,000</h1> <h2>TICKETS</h2>
                                                                    <div className="bid-ends">

                                                                        {userPresent ?
                                                                            <button type="button" className="btn btn-primary" onClick={e => { connectMetaMask(e, 24) }}
                                                                            >
                                                                                <img src="images/kiachain.png" /> KAI 1,000
                                                                            </button> :
                                                                            //    (!isActive) ?
                                                                            //         <button type="button" className="btn btn-primary" disabled={true}
                                                                            //         >
                                                                            //             <img src="images/kiachain.png" /> KAI 1,000
                                                                            //         </button>
                                                                            //         :
                                                                            <button type="button" className="btn btn-primary" onClick={handleShow1}
                                                                            >
                                                                                <img src="images/kiachain.png" /> KAI 1,000
                                                                            </button>
                                                                        }
                                                                        <Modal size="lg" className="modal-dark" show={show1} onHide={loader ? '' : handleClose}
                                                                        >
                                                                            {loader ?
                                                                                <Modal.Header ></Modal.Header>
                                                                                :
                                                                                <Modal.Header closeButton></Modal.Header>
                                                                            }

                                                                            <Modal.Body className="pt-0">
                                                                                <div className="amountdetail mt-0">
                                                                                    <Row className="">
                                                                                        <Col lg={12} className="text-center">
                                                                                            <div className="head">
                                                                                                <h4>ENTER COMPETITION</h4>

                                                                                            </div>

                                                                                        </Col>
                                                                                        <Col lg={2}>

                                                                                        </Col>
                                                                                        <Col lg={8}>
                                                                                            <Row className="align-items-center">

                                                                                                <Col lg={6} md={6} xs={6} className="mb-3 h-name">
                                                                                                    <h4>Number of Tickets</h4>
                                                                                                </Col>
                                                                                                <Col lg={6} md={6} xs={6} className="mb-3">
                                                                                                    <div className="n-multiplier">
                                                                                                        <h4>{commaNumber(1000)}</h4>
                                                                                                        <span className="">x</span>
                                                                                                        <Form.Control type="text" name="number" placeholder="1" value={value1} onChange={e => { handleChange1(e) }} />

                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col lg={6} md={6} xs={6} className="h-name mb-3">
                                                                                                    <h4>Bonus Tickets</h4>
                                                                                                </Col>
                                                                                                <Col lg={6} md={6} xs={6} className="mb-3">
                                                                                                    <h4>{commaNumber(bonus1)}</h4>

                                                                                                </Col>
                                                                                                <Col lg={6} md={6} xs={6} className="h-name mb-3">
                                                                                                    <h4>Total Tickets</h4>
                                                                                                </Col>
                                                                                                <Col lg={6} md={6} xs={6} className="mb-3">
                                                                                                    <h4>{commaNumber(totalTicket1)}</h4>

                                                                                                </Col>
                                                                                                <Col lg={6} md={6} xs={6} className="h-name mb-3">
                                                                                                    <h4>Total Kai</h4>
                                                                                                </Col>
                                                                                                <Col lg={6} md={6} xs={6} className="mb-3">
                                                                                                    <h4>{commaNumber(parseFloat(totalKai1))}</h4>

                                                                                                </Col>

                                                                                                <Col lg={12} className="mb-3 k-btn text-center">
                                                                                                    {offer1Error ? <span style={{ color: "red" }}>Can not select more then 9</span> : <span></span>}
                                                                                                    {offer1ErrorMax ? <span style={{ color: "red" }}>Tickets not available. Please Choose less quantity</span> : <span></span>}
                                                                                                    {

                                                                                                        offer1Error ?
                                                                                                            <button type="button" className="btn btn-primary" disabled={true} >
                                                                                                                <img src="images/kiachain.png" /> KAI 1,000
                                                                                                            </button>
                                                                                                            :
                                                                                                            !(totalTicket1 > 0) ?
                                                                                                                <button type="button" className="btn btn-primary" disabled={true} >
                                                                                                                    <img src="images/kiachain.png" /> KAI 1,000
                                                                                                                </button>
                                                                                                                :
                                                                                                                loader ?
                                                                                                                    <button type="button" className="btn btn-primary" disabled={true}//onClick={e => { buyTickets(e, 1,value1) }}//onClick={handleShow4} 
                                                                                                                    >
                                                                                                                        <img src="images/kiachain.png" /> Processing....
                                                                                                                    </button>
                                                                                                                    :
                                                                                                                    offer1ErrorMax ?
                                                                                                                        <button type="button" className="btn btn-primary" disable={true}
                                                                                                                        >
                                                                                                                            <img src="images/kiachain.png" /> KAI 1,000
                                                                                                                        </button>
                                                                                                                        :

                                                                                                                        <button type="button" className="btn btn-primary" onClick={e => { buyTickets(e, 1, value1) }}//onClick={handleShow4} 
                                                                                                                        >
                                                                                                                            <img src="images/kiachain.png" /> {commaNumber(parseFloat(totalKai1))}
                                                                                                                        </button>
                                                                                                    }
                                                                                                </Col>
                                                                                            </Row>

                                                                                        </Col>
                                                                                        <Col lg={2}>

                                                                                        </Col>



                                                                                    </Row>

                                                                                </div>
                                                                            </Modal.Body>

                                                                        </Modal>


                                                                        <Modal size="lg" className="modal-dark" show={newshow4} //onHide={handleClose}
                                                                        >
                                                                            <Modal.Header closeButton>

                                                                            </Modal.Header>
                                                                            <Modal.Body className="pt-0">
                                                                                <div className="amountdetail pb-5">
                                                                                    <Row className="">
                                                                                        <Col lg={12} className="text-center">
                                                                                            <div className="head">
                                                                                                <h4>DECIDE WHAT TO DO</h4>

                                                                                            </div>

                                                                                        </Col>
                                                                                        <Col lg={12} className="text-center">
                                                                                            <div className="">
                                                                                                <p style={{ color: "red" }}>Please Do Not Refresh the page before sending mail</p>

                                                                                            </div>

                                                                                        </Col>

                                                                                        <Col lg={12}>
                                                                                            <Row className="align-items-center ">

                                                                                                <Col lg={12} className="mb-3 text-center">
                                                                                                    <h4>You have just succeeded in purchasing<br />{commaNumber(totalTicket1)} Tickets</h4>
                                                                                                </Col>
                                                                                                <Col md={12}>
                                                                                                    <div className="buttons-box">
                                                                                                        <Row>
                                                                                                            <Col xs={6} className="border-right">
                                                                                                                <div className="succeed-d">
                                                                                                                    <h3>Register account</h3>
                                                                                                                    <button type="button" onClick={e => { registerAccount(e) }}>enter email to create your account</button>
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                            <Col xs={6}>
                                                                                                                <div className="succeed-d">
                                                                                                                    <h3>Continue as guest</h3>
                                                                                                                    <button type="button" onClick={e => { showGuest(e, 1) }}>enter email for purchase receipt</button>
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </div>
                                                                                                </Col>

                                                                                            </Row>


                                                                                        </Col>



                                                                                    </Row>

                                                                                </div>
                                                                            </Modal.Body>

                                                                        </Modal>



                                                                        <Modal size="lg" className="modal-dark" show={newshow1} onHide={handleClose1}
                                                                        >
                                                                            <Modal.Header closeButton>

                                                                            </Modal.Header>
                                                                            <Modal.Body className="pt-0">
                                                                                <div className="amountdetail">
                                                                                    <Row className="">
                                                                                        <Col lg={12} className="text-center">
                                                                                            <div className="head">
                                                                                                <h4>PURCHASE CONFIRMED</h4>

                                                                                            </div>

                                                                                        </Col>
                                                                                        {/* <Col lg={12} className="text-center">
                                                                                            <div className="">
                                                                                                <p style={{ color: "red" }}>Please Do Not Refresh the page before sending mail</p>

                                                                                            </div>

                                                                                        </Col> */}
                                                                                        <Col lg={2}>

                                                                                        </Col>
                                                                                        <Col lg={8}>
                                                                                            <Row className="align-items-center ">

                                                                                                <Col lg={12} className="mb-3 text-center">
                                                                                                    <h4>You have just succeeded in purchasing<br />{commaNumber(totalTicket1)} Tickets</h4>
                                                                                                </Col>

                                                                                                <Col lg={12} className="text-center mb-3">
                                                                                                    <h4>Your Purchase Receipt has been sent to your Registered Email Address</h4>
                                                                                                    {/* <Row className="">
                                                                                                        <Col lg={2}>
                                                                                                        </Col>
                                                                                                        <Col lg={8}>
                                                                                                            <Form.Control type="text" name="email" placeholder="Your email" className="text-center" onChange={e => { handleEmailChange(e) }} />
                                                                                                        </Col>
                                                                                                        <Col lg={2}>
                                                                                                        </Col>
                                                                                                    </Row> */}

                                                                                                </Col>



                                                                                                <Col lg={12} className="mb-3 k-btn text-center">

                                                                                                    <sapn style={{ color: "red" }} >You can close this window</sapn>

                                                                                                </Col>
                                                                                            </Row>

                                                                                        </Col>
                                                                                        <Col lg={2}>

                                                                                        </Col>



                                                                                    </Row>

                                                                                </div>
                                                                            </Modal.Body>

                                                                        </Modal>
                                                                    </div>
                                                                    {/* <p className="bg-clip-text">BONUS <br />20,000 FREE TICKETS</p> */}
                                                                </div>
                                                            </div>
                                                        </Col>


                                                        <Col lg={4} mb={6} className='mb-3'>
                                                            <div className="btn-gradient-1">
                                                                <div className="entry-ticket pt-3">
                                                                    <span className="uppercase">&nbsp;</span>
                                                                    <h1>11,000</h1> <h2>TICKETS</h2>
                                                                    <div className="bid-ends">
                                                                        {/* <button type="button" className="btn btn-primary" disabled={isActive && (!offers3Error) ? false : true} onClick={e => buyTickets(e, "3")}>
                                                                        <img src="images/kiachain.png" /> KAI 100,000
                                                                    </button> */}
                                                                        {userPresent ?
                                                                            <button type="button" className="btn btn-primary" onClick={e => connectMetaMask(e, 24)}
                                                                            >
                                                                                <img src="images/kiachain.png" /> KAI 10,000
                                                                            </button> :
                                                                            //    (!isActive) ?
                                                                            //     <button type="button" className="btn btn-primary" disabled={true}
                                                                            //     >
                                                                            //         <img src="images/kiachain.png" /> KAI 10,000
                                                                            //     </button>
                                                                            //     :
                                                                            <button type="button" className="btn btn-primary" onClick={handleShow2} >
                                                                                <img src="images/kiachain.png" /> KAI 10,000
                                                                            </button>}
                                                                        <Modal size="lg" className="modal-dark" show={show2} onHide={loader ? '' : handleClose}>

                                                                            {loader ?
                                                                                <Modal.Header ></Modal.Header>
                                                                                :
                                                                                <Modal.Header closeButton></Modal.Header>
                                                                            }
                                                                            <Modal.Body className="pt-0">
                                                                                <div className="amountdetail mt-0">
                                                                                    <Row className="">
                                                                                        <Col lg={12} className="text-center">
                                                                                            <div className="head">
                                                                                                <h4>ENTER COMPETITION</h4>

                                                                                            </div>

                                                                                        </Col>
                                                                                        <Col lg={2}>

                                                                                        </Col>
                                                                                        <Col lg={8}>
                                                                                            <Row className="align-items-center">

                                                                                                <Col lg={6} md={6} xs={6} className="mb-3 h-name">
                                                                                                    <h4>Number of Tickets</h4>
                                                                                                </Col>
                                                                                                <Col lg={6} md={6} xs={6} className="mb-3">
                                                                                                    <div className="n-multiplier">
                                                                                                        <h4>{commaNumber(10000)}</h4>
                                                                                                        <span className="">x</span>
                                                                                                        <Form.Control type="text" name="number" placeholder="1" value={value2} onChange={e => { handleChange2(e) }} />

                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col lg={6} md={6} xs={6} className="h-name mb-3">
                                                                                                    <h4>Bonus Tickets</h4>
                                                                                                </Col>
                                                                                                <Col lg={6} md={6} xs={6} className="mb-3">
                                                                                                    <h4>{commaNumber(bonus2)}</h4>

                                                                                                </Col>
                                                                                                <Col lg={6} md={6} xs={6} className="h-name mb-3">
                                                                                                    <h4>Total Tickets</h4>
                                                                                                </Col>
                                                                                                <Col lg={6} md={6} xs={6} className="mb-3">
                                                                                                    <h4>{commaNumber(totalTicket2)}</h4>

                                                                                                </Col>
                                                                                                <Col lg={6} md={6} xs={6} className="h-name mb-3">
                                                                                                    <h4>Total Kai</h4>
                                                                                                </Col>
                                                                                                <Col lg={6} md={6} xs={6} className="mb-3">
                                                                                                    <h4>{commaNumber(parseFloat(totalKai2))}</h4>

                                                                                                </Col>

                                                                                                <Col lg={12} className="mb-3 k-btn text-center">
                                                                                                    {offer2Error ? <span style={{ color: "red" }}>Can not select more then 9</span> : <span></span>}
                                                                                                    {offer2ErrorMax ? <span style={{ color: "red" }}>Tickets not available. Please Choose less quantity</span> : <span></span>}
                                                                                                    {offer2Error ? <button type="button" className="btn btn-primary" disabled={true} >
                                                                                                        <img src="images/kiachain.png" /> KAI 10,000
                                                                                                    </button>
                                                                                                        :
                                                                                                        !(totalTicket2 > 0) ?
                                                                                                            <button type="button" className="btn btn-primary" disabled={true} >
                                                                                                                <img src="images/kiachain.png" /> KAI 10,000
                                                                                                            </button>
                                                                                                            :
                                                                                                            loader ?
                                                                                                                <button type="button" className="btn btn-primary" disabled={true}//onClick={e => { buyTickets(e, 1,value1) }}//onClick={handleShow4} 
                                                                                                                >
                                                                                                                    <img src="images/kiachain.png" /> Processing....
                                                                                                                </button>
                                                                                                                :
                                                                                                                offer2ErrorMax ?
                                                                                                                    <button type="button" className="btn btn-primary" disable={true}
                                                                                                                    >
                                                                                                                        <img src="images/kiachain.png" /> KAI 10,000
                                                                                                                    </button>
                                                                                                                    :

                                                                                                                    <button type="button" className="btn btn-primary" onClick={e => { buyTickets(e, 2, value2) }}//onClick={handleShow5} 
                                                                                                                    >
                                                                                                                        <img src="images/kiachain.png" />KAI {commaNumber(parseFloat(totalKai2))}
                                                                                                                    </button>
                                                                                                    }
                                                                                                </Col>
                                                                                            </Row>

                                                                                        </Col>
                                                                                        <Col lg={2}>

                                                                                        </Col>



                                                                                    </Row>

                                                                                </div>
                                                                            </Modal.Body>

                                                                        </Modal>

                                                                        <Modal size="lg" className="modal-dark" show={newshow5} //onHide={handleClose}
                                                                        >

                                                                            <Modal.Header closeButton>
                                                                            </Modal.Header>

                                                                            <Modal.Body className="pt-0">
                                                                                <div className="amountdetail pb-5">
                                                                                    <Row className="">
                                                                                        <Col lg={12} className="text-center">
                                                                                            <div className="head">
                                                                                                <h4>DECIDE WHAT TO DO</h4>
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col lg={12} className="text-center">
                                                                                            <div className="">
                                                                                                <p style={{ color: "red" }}>Please Do Not Refresh the page before sending mail</p>

                                                                                            </div>

                                                                                        </Col>

                                                                                        <Col lg={12}>
                                                                                            <Row className="align-items-center ">

                                                                                                <Col lg={12} className="mb-3 text-center">
                                                                                                    <h4>You have just succeeded in purchasing<br />{commaNumber(totalTicket2)} Tickets</h4>
                                                                                                </Col>
                                                                                                <Col md={12}>
                                                                                                    <div className="buttons-box">
                                                                                                        <Row>
                                                                                                            <Col xs={6} className="border-right">
                                                                                                                <div className="succeed-d">
                                                                                                                    <h3>Register account</h3>
                                                                                                                    <button type="button" onClick={e => { registerAccount(e) }}>enter email to create your account</button>
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                            <Col xs={6}>
                                                                                                                <div className="succeed-d">
                                                                                                                    <h3>Continue as guest</h3>
                                                                                                                    <button type="button" onClick={e => { showGuest(e, 2) }}>enter email for purchase receipt</button>
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </div>
                                                                                                </Col>

                                                                                            </Row>


                                                                                        </Col>



                                                                                    </Row>

                                                                                </div>
                                                                            </Modal.Body>

                                                                        </Modal>



                                                                        <Modal size="lg" className="modal-dark" show={newshow2} onHide={handleClose1}
                                                                        >
                                                                            <Modal.Header closeButton>
                                                                            </Modal.Header>
                                                                            <Modal.Body className="pt-0">
                                                                                <div className="amountdetail">
                                                                                    <Row className="">
                                                                                        <Col lg={12} className="text-center">
                                                                                            <div className="head">
                                                                                                <h4>PURCHASE CONFIRMED</h4>

                                                                                            </div>

                                                                                        </Col>
                                                                                        <Col lg={12} className="text-center">
                                                                                            <div className="">
                                                                                                <p style={{ color: "red" }}>Please Do Not Refresh the page before sending mail</p>

                                                                                            </div>

                                                                                        </Col>


                                                                                        <Col lg={2}>

                                                                                        </Col>
                                                                                        <Col lg={8}>
                                                                                            <Row className="align-items-center ">

                                                                                                <Col lg={12} className="mb-3 text-center">
                                                                                                    <h4>You have just succeeded in purchasing<br />{commaNumber(totalTicket2)} TICKETS</h4>
                                                                                                </Col>

                                                                                                <Col lg={12} className="text-center mb-3">
                                                                                                    <h4>Your Purchase Receipt has been sent to your Registered Email Address</h4>
                                                                                                    {/* <Row className="">
                                                                                                        <Col lg={2}>
                                                                                                        </Col>
                                                                                                        <Col lg={8}>
                                                                                                            <Form.Control type="text" name="email" placeholder="Your email" className="text-center" onChange={e => { handleEmailChange(e) }} />
                                                                                                        </Col>
                                                                                                        <Col lg={2}>
                                                                                                        </Col>
                                                                                                    </Row> */}

                                                                                                </Col>



                                                                                                <Col lg={12} className="mb-3 k-btn text-center">

                                                                                                    <sapn style={{ color: "red" }} >You can close this window</sapn>

                                                                                                </Col>
                                                                                            </Row>

                                                                                        </Col>
                                                                                        <Col lg={2}>

                                                                                        </Col>



                                                                                    </Row>

                                                                                </div>
                                                                            </Modal.Body>

                                                                        </Modal>
                                                                    </div>
                                                                    <p className="bg-clip-text">BONUS <br />1,000 FREE TICKETS</p>
                                                                </div>
                                                            </div>
                                                        </Col>




                                                        <Col lg={4} mb={6} className='mb-3'>
                                                            <div className="btn-gradient-1 ">
                                                                <div className="entry-ticket pt-3">
                                                                    <span className="uppercase">Recommended</span>
                                                                    <h1>120,000</h1> <h2>TICKETS</h2>
                                                                    <div className="bid-ends">
                                                                        {/* <button type="button" className="btn btn-primary" disabled={isActive && (!offers3Error) ? false : true} onClick={e => buyTickets(e, "3")}>
                                                                        <img src="images/kiachain.png" /> KAI 100,000
                                                                    </button> */}
                                                                        {userPresent ?
                                                                            <button type="button" className="btn btn-primary" onClick={e => connectMetaMask(e, 24)}
                                                                            >
                                                                                <img src="images/kiachain.png" /> KAI 100,000
                                                                            </button> :
                                                                            //    (!isActive) ?
                                                                            //     <button type="button" className="btn btn-primary" disabled={true}
                                                                            //     >
                                                                            //         <img src="images/kiachain.png" /> KAI 100,000
                                                                            //     </button>
                                                                            //     :
                                                                            <button type="button" className="btn btn-primary" onClick={handleShow3} >
                                                                                <img src="images/kiachain.png" /> KAI 100,000
                                                                            </button>}
                                                                        <Modal size="lg" className="modal-dark" show={show3} onHide={loader ? '' : handleClose}>
                                                                            {loader ?
                                                                                <Modal.Header ></Modal.Header>
                                                                                :
                                                                                <Modal.Header closeButton></Modal.Header>
                                                                            }
                                                                            <Modal.Body className="pt-0">
                                                                                <div className="amountdetail mt-0">
                                                                                    <Row className="">
                                                                                        <Col lg={12} className="text-center">
                                                                                            <div className="head">
                                                                                                <h4>ENTER COMPETITION</h4>

                                                                                            </div>

                                                                                        </Col>
                                                                                        <Col lg={2}>

                                                                                        </Col>
                                                                                        <Col lg={8}>
                                                                                            <Row className="align-items-center">

                                                                                                <Col lg={6} md={6} xs={6} className="mb-3 h-name">
                                                                                                    <h4>Number of Tickets</h4>
                                                                                                </Col>
                                                                                                <Col lg={6} md={6} xs={6} className="mb-3">
                                                                                                    <div className="n-multiplier">
                                                                                                        <h4>{commaNumber(100000)}</h4>
                                                                                                        <span className="">x</span>
                                                                                                        <Form.Control type="text" name="number" placeholder="1" value={value3} onChange={e => { handleChange3(e) }} />

                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col lg={6} md={6} xs={6} className="h-name mb-3">
                                                                                                    <h4>Bonus Tickets</h4>
                                                                                                </Col>
                                                                                                <Col lg={6} md={6} xs={6} className="mb-3">
                                                                                                    <h4>{commaNumber(bonus3)}</h4>

                                                                                                </Col>
                                                                                                <Col lg={6} md={6} xs={6} className="h-name mb-3">
                                                                                                    <h4>Total Tickets</h4>
                                                                                                </Col>
                                                                                                <Col lg={6} md={6} xs={6} className="mb-3">
                                                                                                    <h4>{commaNumber(totalTicket3)}</h4>

                                                                                                </Col>
                                                                                                <Col lg={6} md={6} xs={6} className="h-name mb-3">
                                                                                                    <h4>Total Kai</h4>
                                                                                                </Col>
                                                                                                <Col lg={6} md={6} xs={6} className="mb-3">
                                                                                                    <h4>{commaNumber(totalKai3)}</h4>

                                                                                                </Col>

                                                                                                <Col lg={12} className="mb-3 k-btn text-center">
                                                                                                    {offer3Error ? <span style={{ color: "red" }}>Can not select more then 12</span> : <span></span>}
                                                                                                    {offer3ErrorMax ? <span style={{ color: "red" }}>Tickets not available. Please Choose less quantity</span> : <span></span>}
                                                                                                    {offer3Error ? <button type="button" className="btn btn-primary" disabled={true} >
                                                                                                        <img src="images/kiachain.png" /> KAI 100,000
                                                                                                    </button>
                                                                                                        :
                                                                                                        !(totalTicket3 > 0) ?
                                                                                                            <button type="button" className="btn btn-primary" disabled={true} >
                                                                                                                <img src="images/kiachain.png" /> KAI 100,000
                                                                                                            </button>
                                                                                                            :
                                                                                                            loader ?
                                                                                                                <button type="button" className="btn btn-primary" disabled={true}//onClick={e => { buyTickets(e, 1,value1) }}//onClick={handleShow4} 
                                                                                                                >
                                                                                                                    <img src="images/kiachain.png" /> Processing....
                                                                                                                </button>
                                                                                                                :
                                                                                                                offer3ErrorMax ?
                                                                                                                    <button type="button" className="btn btn-primary" disable={true}
                                                                                                                    >
                                                                                                                        <img src="images/kiachain.png" /> KAI 100,000
                                                                                                                    </button>
                                                                                                                    :

                                                                                                                    <button type="button" className="btn btn-primary" onClick={e => { buyTickets(e, 3, value3) }}// onClick={handleShow6}
                                                                                                                    >
                                                                                                                        <img src="images/kiachain.png" /> {commaNumber(parseFloat(totalKai3))}
                                                                                                                    </button>
                                                                                                    }
                                                                                                </Col>
                                                                                            </Row>

                                                                                        </Col>
                                                                                        <Col lg={2}>

                                                                                        </Col>



                                                                                    </Row>

                                                                                </div>
                                                                            </Modal.Body>

                                                                        </Modal>


                                                                        <Modal size="lg" className="modal-dark" show={newshow6} //onHide={handleClose}
                                                                        >
                                                                            <Modal.Header closeButton>

                                                                            </Modal.Header>
                                                                            <Modal.Body className="pt-0">
                                                                                <div className="amountdetail pb-5">
                                                                                    <Row className="">
                                                                                        <Col lg={12} className="text-center">
                                                                                            <div className="head">
                                                                                                <h4>DECIDE WHAT TO DO</h4>

                                                                                            </div>

                                                                                        </Col>
                                                                                        <Col lg={12} className="text-center">
                                                                                            <div className="">
                                                                                                <p style={{ color: "red" }}>Please Do Not Refresh the page before sending mail</p>

                                                                                            </div>

                                                                                        </Col>

                                                                                        <Col lg={12}>
                                                                                            <Row className="align-items-center ">

                                                                                                <Col lg={12} className="mb-3 text-center">
                                                                                                    <h4>You have just succeeded in purchasing<br />{commaNumber(totalTicket3)} Tickets</h4>
                                                                                                </Col>
                                                                                                <Col md={12}>
                                                                                                    <div className="buttons-box">
                                                                                                        <Row>
                                                                                                            <Col xs={6} className="border-right">
                                                                                                                <div className="succeed-d">
                                                                                                                    <h3>Register account</h3>
                                                                                                                    <button type="button" onClick={e => { registerAccount(e) }}>enter email to create your account</button>
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                            <Col xs={6}>
                                                                                                                <div className="succeed-d">
                                                                                                                    <h3>Continue as guest</h3>
                                                                                                                    <button type="button" onClick={e => { showGuest(e, 3) }}>enter email for purchase receipt</button>
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </div>
                                                                                                </Col>

                                                                                            </Row>


                                                                                        </Col>



                                                                                    </Row>

                                                                                </div>
                                                                            </Modal.Body>

                                                                        </Modal>





                                                                        <Modal size="lg" className="modal-dark" show={newshow3} onHide={handleClose1}
                                                                        >
                                                                            <Modal.Header closeButton>
                                                                                {/* <p>REMAINING TICKET 50,000</p> */}
                                                                            </Modal.Header>
                                                                            <Modal.Body className="pt-0">
                                                                                <div className="amountdetail">
                                                                                    <Row className="">
                                                                                        <Col lg={12} className="text-center">

                                                                                            <div className="head">
                                                                                                <h4>PURCHASE CONFIRMED</h4>

                                                                                            </div>



                                                                                        </Col>
                                                                                        <Col lg={12} className="text-center">
                                                                                            <div className="">
                                                                                                <p style={{ color: "red" }}>Please Do Not Refresh the page before sending mail</p>

                                                                                            </div>

                                                                                        </Col>
                                                                                        <Col lg={2}>

                                                                                        </Col>
                                                                                        <Col lg={8}>
                                                                                            <Row className="align-items-center ">

                                                                                                <Col lg={12} className="mb-3 text-center">
                                                                                                    <h4>You have just succeeded in purchasing<br />{commaNumber(totalTicket3)} Tickets</h4>
                                                                                                </Col>

                                                                                                <Col lg={12} className="text-center mb-3">
                                                                                                    <h4>Your Purchase Receipt has been sent to your Registered Email Address</h4>
                                                                                                    {/* <Row className="">
                                                                                                        <Col lg={2}>
                                                                                                        </Col>
                                                                                                        <Col lg={8}>
                                                                                                            <Form.Control type="text" name="email" placeholder="Your email" className="text-center" onChange={e => { handleEmailChange(e) }} />
                                                                                                        </Col>
                                                                                                        <Col lg={2}>
                                                                                                        </Col>
                                                                                                    </Row> */}

                                                                                                </Col>



                                                                                                <Col lg={12} className="mb-3 k-btn text-center">

                                                                                                    <sapn style={{ color: "red" }} >You can close this window</sapn>

                                                                                                </Col>
                                                                                            </Row>

                                                                                        </Col>
                                                                                        <Col lg={2}>

                                                                                        </Col>



                                                                                    </Row>

                                                                                </div>
                                                                            </Modal.Body>

                                                                        </Modal>
                                                                    </div>
                                                                    <p className="bg-clip-text">BONUS <br />20,000 FREE TICKETS</p>
                                                                </div>
                                                            </div>
                                                        </Col>


                                                    </Row>
                                                </div>
                                                <div className="selected-cards mt-4 " style={{ minHeight: "290px" }}>
                                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                                        <Row>

                                                            <Nav variant="pills" >
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="first">ACTIVITY</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="second">PARTICIPANTS</Nav.Link>
                                                                </Nav.Item>
                                                            </Nav>


                                                            <Tab.Content className="mt-4">
                                                                <Tab.Pane eventKey="first">
                                                                    <div className="member">
                                                                        <ul>
                                                                            {activity.length > 0 ?

                                                                                activity.map((item) => (
                                                                                    <li>
                                                                                        <div className="list-name">
                                                                                            <a className="d-flex align-items-center">
                                                                                                {item.profile_pic != '' && item.profile_pic != null ?
                                                                                                    <span><img src={`${config.imageURL}` + `${item.profile_pic}`} /></span>
                                                                                                    :
                                                                                                    <span><img src="images/logo.png" /></span>
                                                                                                }


                                                                                                <h3 className="block">{item.user_address.toString().substring(0, 3)}...{item.user_address.toString().substring(item.user_address.length - 3, item.user_address.length)}
                                                                                                <div className="uppercase">Ticket Numbers {commaNumber(item.ticket[0])} - {commaNumber(item.ticket[1])}</div>
                                                                                                </h3>
                                                                                            </a>
                                                                                            <div>
                                                                                                <a>{item.timestamp.toString().substring(0, 10)} {item.timestamp.toString().substring(11, 19)}</a>


                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                ))
                                                                                :
                                                                                <li>
                                                                                    <div className="list-name">
                                                                                        {/* <a className="d-flex align-items-center">
                                                                                    <span><img src="images/logo.png" /></span>


                                                                                    <h3 className="block"> <div className="uppercase"></div></h3>

                                                                                </a> */}
                                                                                        <div><a >  </a></div>

                                                                                    </div>
                                                                                </li>
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="second">
                                                                    <div className="member">
                                                                        <ul>

                                                                            {
                                                                                participants.length > 0
                                                                                    ?
                                                                                    participants.map((item) => (
                                                                                        <li>
                                                                                            <div className="list-name">
                                                                                                <a className="d-flex align-items-center">
                                                                                                    {item.profile_pic != '' && item.profile_pic != null ?
                                                                                                        <span><img src={`${config.imageURL}` + `${item.profile_pic}`} /></span>
                                                                                                        :
                                                                                                        <span><img src="images/logo.png" /></span>
                                                                                                    }
                                                                                                    {item.userName == '' || item.userName == null ?
                                                                                                        <h3 className="block">{item.user_address.toString().substring(0, 3)}...{item.user_address.toString().substring(item.user_address.length - 3, item.user_address.length)}<div className="uppercase">total number of tickets {commaNumber(item.totalTicket)}</div></h3>
                                                                                                        :
                                                                                                        <h3 className="block">{item.userName.toString()}<div className="uppercase">total number of tickets {commaNumber(item.totalTicket)}</div></h3>
                                                                                                    }
                                                                                                </a>
                                                                                                {/* <div>
                                                                                                    <a>{item.timestamp.toString().substring(0, 10)} {item.timestamp.toString().substring(11, 19)}</a>

                                                                                                </div> */}

                                                                                            </div>
                                                                                        </li>
                                                                                    ))
                                                                                    :
                                                                                    <li>
                                                                                        <div className="list-name">
                                                                                            {/* <a className="d-flex align-items-center">
                                                                                        <span><img src="images/logo.png" /></span>
                                                                                        <h3 className="block"> </h3>
                                                                                    </a> */}
                                                                                            <div><a ></a></div>

                                                                                        </div>
                                                                                    </li>

                                                                            }
                                                                        </ul>

                                                                    </div>
                                                                </Tab.Pane>
                                                            </Tab.Content>

                                                        </Row>
                                                    </Tab.Container>

                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    )
}
export default TicketDetails;