
  
  const initialState = {};
  
  export default function Reducer(state = initialState, action) {
    switch (action.type) {
      case 'WALLET_CONNECT':
        return {
            ...state,
            walletdata: action.payload,
        };
    default:
        return state;
    }
  }