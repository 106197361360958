import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import config from "../config/config";

import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import "../componets/css/buykai.css";
import {
  getFeesPercentageAPI,
  insertBuyKaiAPI,
  getUSDTChains,
  getDepositAddress,
  currentKaiRateInUsdt,
} from "../Action/user.action";
import Web3 from "web3";

const Buykai = () => {
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [processComplete, setProcessComplete] = useState(false);

  const [getBlockchains, setGetBlockchains] = useState([]);
  const [BlockchainSelectError, setBlockchainSelectError] = useState(false);
  const options = ["Market", "Limit"];
  const [selectedOption, setSelectedOption] = useState("Market");
  const [selectedBlockchain, setSelectedBlockchain] = useState("KCC");

  const [walletAddressForTron, setWalletAddressForTron] = useState(false);
  const [web3WalletAddress, setWeb3WalletAddress] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [currentKaiRateInUSDT, setCurrentKaiRateInUSDT] = useState("0.0051235");
  const [fees, setFees] = useState(1);

  const [usdtAmount, setUsdtAmount] = useState(0);
  const [kaiAmount, setKaiAmount] = useState(0);

  const [jis,setjis] = useState("false")
  

  useEffect(() => {
    const intervalId = setInterval(() => {
      getCurrentRateAPI();
    }, 3000);

    // Clean up the interval to avoid memory leaks
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getFeesPercentage();
    getAllBlockchainsAPI();
  }, []);
  useEffect(() => {
   fetchKardiachainWalletdetails();
  }, []);

  const fetchKardiachainWalletdetails=async()=>{

    try{
     if(window.kardiachain){
 
      let web3 = new Web3(window.kardiachain);
      if (window.kardiachain.isKaiWallet) {

          window.kardiachain.enable();
      }
      // const currentChainId = await web3.eth.net.getId();

      // if (currentChainId !== config.chainId) {
      //     try {
      //         const data = await web3.currentProvider.request({
      //             method: "wallet_switchEthereumChain",
      //             params: [{ chainId: Web3.utils.toHex(config.chainId) }],
      //         });

      //     } catch (switchError) {
      //         if (switchError.code === 4902) {
      //             toast.error("Please connect with Kardichain network");
      //             return false;
      //         }
      //     }
      // }
      const  accounts = await web3.eth.getAccounts()
      setjis(accounts)
      //const accounts = await window.kardiachain.selectedAddress
setWeb3WalletAddress(accounts[0])
     }else{
      console.log("no kardiachain wallet found")
     } 

    }catch(error){
      console.log(error)
    }
  }

  const getFeesPercentage = async () => {
    try {
      let result = await getFeesPercentageAPI();
      setFees(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCurrentRateAPI = async () => {
    try {
      let result = await currentKaiRateInUsdt();
      if (result.status) {
        setCurrentKaiRateInUSDT(parseFloat(result.data).toFixed(6));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllBlockchainsAPI = async () => {
    console.log("getAllBlockchainsAPI");
    try {
      let result = await getUSDTChains();
      setGetBlockchains(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    if (!selectedBlockchain || selectedBlockchain == "Blockchain Network") {
      setBlockchainSelectError(true);
    }
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue === "Limit" ? "Market" : selectedValue);
  };

  const methodsetWeb3WalletAddress = (e) => {
    setWeb3WalletAddress(e.target.value);
  };

  const changeEmailAddress = (e) => {
    setEmailAddress(e.target.value);
  };

  const changeUsdtAmount = (e) => {
    let numericInput = e.target.value.replace(/[^0-9.]/g, "");
    const decimalIndex = numericInput.indexOf(".");
    if (decimalIndex !== -1) {
      const decimalPart = numericInput.substring(decimalIndex + 1);
      if (decimalPart.length > 4) {
        numericInput = numericInput.substring(0, decimalIndex + 5);
      }
    }
    let usdts = numericInput; //e.target.value;
    setUsdtAmount(usdts);
    let realUsdt = usdts - (usdts * fees) / 100;
    realUsdt = parseFloat(realUsdt);
    let realKai = realUsdt / currentKaiRateInUSDT;
    realKai = realKai;
    setKaiAmount(parseFloat(realKai).toFixed(4));
  };

  const changeKaiAmount = (e) => {
    const numericInput = e.target.value.replace(/[^0-9.]/g, "");

    let kai = numericInput; //e.target.value
    setKaiAmount(kai);
    let amount = currentKaiRateInUSDT * kai;
    let newAmount = amount + (amount * fees) / 100;
    setUsdtAmount(parseFloat(newAmount).toFixed(4));
  };

  const changeCurrentRate = (e) => {
    setCurrentKaiRateInUSDT(e.target.value);
  };

  const handleChange2 = (event) => {
    setSelectedBlockchain(event.target.value);
    setBlockchainSelectError(false);
  };

  const buyKaiSubmit = async (e) => {
    e.preventDefault();
    setShow(true);
    setLoader(true);

    try {
      if (selectedBlockchain == "Blockchain Network") {
        setShow(false);
        setLoader(false);
        toast.error("Please select network");
        return;
      }
      if (usdtAmount <= 0) {
        setShow(false);
        setLoader(false);
        toast.error("Please enter valid amount");
        return;
      }
      if (usdtAmount < 10) {
        setShow(false);
        setLoader(false);
        toast.error("Please enter amount above 10 USDT");
        return;
      }
      if (!isValidWeb3Address(web3WalletAddress)) {
        setShow(false);
        setLoader(false);
        toast.error("Please enter valid web3 wallet address");
        return;
      }
      if (!isValidEmail(emailAddress)) {
        setShow(false);
        setLoader(false);
        toast.error("Please enter valid email address");
        return;
      }
      let { transactionHash, userAddress, status } = await connectWallet();

      if (!status) {
        setShow(false)
        setLoader(false)
        return
      }
      let blockchain = selectedBlockchain;
      let orderType = selectedOption;
      let data = {
        userAddress: userAddress,
        transactionHash:
        transactionHash,
        blockchain,
        orderType,
        kaiAmount,
        usdtAmount,
        emailAddress,
        rate: currentKaiRateInUSDT,
      };
      await buyKaiApi(data);
    } catch (error) {
      setShow(false);
      setLoader(false);
      toast.error(error);
    }
  };
  const buyKaiApi = async (data) => {
    try {
      let result = await insertBuyKaiAPI(data);
      if (result.status) {
        toast.success(result.msg);
        setLoader(false);
        setProcessComplete(true);

        // here I want to setShow(false) and setProcessComplete(false) after 3 seconds
        // setTimeout(() => {
        //   setShow(false);
        //   setProcessComplete(false);
        // }, 3000);
      } else {
        setShow(false);
        setLoader(false);
        toast.error(result.msg ? result.msg : result.error);
      }
    } catch (error) {
      setShow(false);
      setLoader(false);
      console.log(error);
    }
  };
  const isValidWeb3Address = (address) => {
    if (
      typeof address !== "string" ||
      address.length !== 42 ||
      !address.startsWith("0x")
    ) {
      return false;
    }

    const hexRegex = /^[0-9A-Fa-f]+$/;
    const restOfAddress = address.substring(2); // Exclude the '0x' prefix
    return hexRegex.test(restOfAddress);
  };
  function isValidEmail(email) {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regex pattern
    return emailRegex.test(email);
  }
  const connectWallet = async () => {
    try {
      let web3;
      let currentAccount;

      if (selectedBlockchain == "ERC20" || selectedBlockchain == "KCC") {
        if (window.ethereum) {
          let metamsakAccount = await window.ethereum.request({
            method: "eth_requestAccounts",
          });

          currentAccount = metamsakAccount[0];
          web3 = new Web3(window.ethereum);

          const currentChainId = await web3.eth.net.getId();
          console.log(selectedBlockchain);

          if (currentChainId !== config.buyKaiChainId[selectedBlockchain]) {
            try {
              const data = await web3.currentProvider.request({
                method: "wallet_switchEthereumChain",
                params: [
                  {
                    chainId: Web3.utils.toHex(
                      config.buyKaiChainId[selectedBlockchain]
                    ),
                  },
                ],
              });
            } catch (switchError) {
              if (switchError.code === 4902) {
                setShow(false);
                setLoader(false);
                toast.error("Please connect with Kardichain network");

                return { status: false };
              }
            }
          }
        } else {
          setShow(false);
          setLoader(false);
          alert("please install metamask wallet");
          return;
        }
      } else if (selectedBlockchain == "TRC20") {
        if (window.tron) {
          // if(window.tron.tronWeb==false){
          //   toast.error("Please unlock your tron wallet")
          //   return {status:false};
          // }

          let metamsakAccount = await window.tronLink.request({
            method: "tron_requestAccounts",
          });
          currentAccount = metamsakAccount[0];
          web3 = new Web3(window.tronWeb);
        } else {
          setShow(false);
          setLoader(false);
          alert("please install tronlink wallet");
          return { status: false };
        }
      }
      let resultData = await getDepositAddress({ chain: selectedBlockchain });
      const adminAddressKCC = resultData.data[0].depositAddress;
      const contractAddressKCC = resultData.data[0].contractAddress;
      if (selectedBlockchain == "TRC20") {
        let chain = await window.tronWeb.fullNode.host;

        let bothChain = 0;

        if (chain != "https://api.trongrid.io") bothChain++;

        if (chain != "https://api.tronstack.io") bothChain++;

        if (bothChain >= 2) {
          setShow(false);
          setLoader(false);
          toast.error("Please select Tron main net network");
          return { status: false };
        }

        web3.eth.defaultAccount = currentAccount;
        const currentAccounts = window.tronWeb.defaultAddress.base58;

        const mainBalance = await window.tronWeb.trx.getBalance(currentAccount);

        if (mainBalance <= 0) {
          setShow(false);
          setLoader(false);
          toast.error("Not have enough TRON coins for the transaction.");
          return { status: false };
        }
        const contractAddress = contractAddressKCC; // Replace with your smart contract address
        const contractABI = [
          {
            constant: true,
            inputs: [],
            name: "name",
            outputs: [{ name: "", type: "string" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: false,
            inputs: [{ name: "_upgradedAddress", type: "address" }],
            name: "deprecate",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: false,
            inputs: [
              { name: "_spender", type: "address" },
              { name: "_value", type: "uint256" },
            ],
            name: "approve",
            outputs: [{ name: "", type: "bool" }],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "deprecated",
            outputs: [{ name: "", type: "bool" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: false,
            inputs: [{ name: "_evilUser", type: "address" }],
            name: "addBlackList",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "totalSupply",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: false,
            inputs: [
              { name: "_from", type: "address" },
              { name: "_to", type: "address" },
              { name: "_value", type: "uint256" },
            ],
            name: "transferFrom",
            outputs: [{ name: "", type: "bool" }],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "upgradedAddress",
            outputs: [{ name: "", type: "address" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "decimals",
            outputs: [{ name: "", type: "uint8" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "maximumFee",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "_totalSupply",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: false,
            inputs: [],
            name: "unpause",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: true,
            inputs: [{ name: "_maker", type: "address" }],
            name: "getBlackListStatus",
            outputs: [{ name: "", type: "bool" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "paused",
            outputs: [{ name: "", type: "bool" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: false,
            inputs: [
              { name: "_spender", type: "address" },
              { name: "_subtractedValue", type: "uint256" },
            ],
            name: "decreaseApproval",
            outputs: [{ name: "", type: "bool" }],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: true,
            inputs: [{ name: "who", type: "address" }],
            name: "balanceOf",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: true,
            inputs: [{ name: "_value", type: "uint256" }],
            name: "calcFee",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: false,
            inputs: [],
            name: "pause",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "owner",
            outputs: [{ name: "", type: "address" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "symbol",
            outputs: [{ name: "", type: "string" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: false,
            inputs: [
              { name: "_to", type: "address" },
              { name: "_value", type: "uint256" },
            ],
            name: "transfer",
            outputs: [{ name: "", type: "bool" }],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: true,
            inputs: [{ name: "who", type: "address" }],
            name: "oldBalanceOf",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: false,
            inputs: [
              { name: "newBasisPoints", type: "uint256" },
              { name: "newMaxFee", type: "uint256" },
            ],
            name: "setParams",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: false,
            inputs: [{ name: "amount", type: "uint256" }],
            name: "issue",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: false,
            inputs: [
              { name: "_spender", type: "address" },
              { name: "_addedValue", type: "uint256" },
            ],
            name: "increaseApproval",
            outputs: [{ name: "", type: "bool" }],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: false,
            inputs: [{ name: "amount", type: "uint256" }],
            name: "redeem",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: true,
            inputs: [
              { name: "_owner", type: "address" },
              { name: "_spender", type: "address" },
            ],
            name: "allowance",
            outputs: [{ name: "remaining", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "basisPointsRate",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: true,
            inputs: [{ name: "", type: "address" }],
            name: "isBlackListed",
            outputs: [{ name: "", type: "bool" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: false,
            inputs: [{ name: "_clearedUser", type: "address" }],
            name: "removeBlackList",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "MAX_UINT",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: false,
            inputs: [{ name: "newOwner", type: "address" }],
            name: "transferOwnership",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: false,
            inputs: [{ name: "_blackListedUser", type: "address" }],
            name: "destroyBlackFunds",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              { name: "_initialSupply", type: "uint256" },
              { name: "_name", type: "string" },
              { name: "_symbol", type: "string" },
              { name: "_decimals", type: "uint8" },
            ],
            payable: false,
            stateMutability: "nonpayable",
            type: "constructor",
          },
          {
            anonymous: false,
            inputs: [
              { indexed: true, name: "_blackListedUser", type: "address" },
              { indexed: false, name: "_balance", type: "uint256" },
            ],
            name: "DestroyedBlackFunds",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [{ indexed: false, name: "amount", type: "uint256" }],
            name: "Issue",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [{ indexed: false, name: "amount", type: "uint256" }],
            name: "Redeem",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [{ indexed: false, name: "newAddress", type: "address" }],
            name: "Deprecate",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [{ indexed: true, name: "_user", type: "address" }],
            name: "AddedBlackList",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [{ indexed: true, name: "_user", type: "address" }],
            name: "RemovedBlackList",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              { indexed: false, name: "feeBasisPoints", type: "uint256" },
              { indexed: false, name: "maxFee", type: "uint256" },
            ],
            name: "Params",
            type: "event",
          },
          { anonymous: false, inputs: [], name: "Pause", type: "event" },
          { anonymous: false, inputs: [], name: "Unpause", type: "event" },
          {
            anonymous: false,
            inputs: [
              { indexed: true, name: "previousOwner", type: "address" },
              { indexed: true, name: "newOwner", type: "address" },
            ],
            name: "OwnershipTransferred",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              { indexed: true, name: "owner", type: "address" },
              { indexed: true, name: "spender", type: "address" },
              { indexed: false, name: "value", type: "uint256" },
            ],
            name: "Approval",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              { indexed: true, name: "from", type: "address" },
              { indexed: true, name: "to", type: "address" },
              { indexed: false, name: "value", type: "uint256" },
            ],
            name: "Transfer",
            type: "event",
          },
        ]; // Replace with your smart contract ABI

        const contract = await await window.tronWeb
          .contract()
          .at(contractAddress);

        // Now you can interact with the smart contract methods
        let balanceInSun = await contract.methods
          .balanceOf(currentAccounts)
          .call();

        balanceInSun = await window.tronWeb.toDecimal(balanceInSun._hex);

        let amountInSun;

        if (balanceInSun > 0) {
          amountInSun = window.tronWeb.toSun(usdtAmount);
        } else {
          setShow(false);
          setLoader(false);
          toast.error("insufficient usdt");

          return { status: false };
        }
        if (balanceInSun < amountInSun) {
          setShow(false);
          setLoader(false);
          toast.error("insufficient usdt");
          return { status: false };
        }
        let result;
        result = await contract.transfer(adminAddressKCC, amountInSun).send({
          feeLimit: 1, // set an appropriate fee limit
          callValue: 0, // no TRX to send along with the transaction
          shouldPollResponse: true,
        });
        return {
          status: true,
          transactionHash: result,
          userAddress: web3WalletAddress,
        };
      } else {
        console.log("KCC amount transfer");
        let usdtContract = new web3.eth.Contract(
          config.usdtABI,
          contractAddressKCC
        );

        let usdtBalance = await usdtContract.methods
          .balanceOf(currentAccount)
          .call();
        let latestAmount = (usdtAmount * 10 ** 18).toLocaleString("fullwide", {
          useGrouping: false,
        });
        console.log(usdtBalance, latestAmount);
        if (parseInt(usdtBalance) <= latestAmount) {
          setShow(false);
          setLoader(false);
          toast.error("insufficient usdt");
          return { status: false };
        }

        let transferUsdt = await usdtContract.methods.transfer(
          adminAddressKCC,
          latestAmount
        );

        let encoded_tx = transferUsdt.encodeABI();

        let gasPrice = await web3.eth.getGasPrice();

        let gasLimit = await web3.eth.estimateGas({
          gasPrice: web3.utils.toHex(gasPrice),
          to: contractAddressKCC,
          from: currentAccount,
          data: encoded_tx,
        });

        let trx = await web3.eth.sendTransaction({
          gasPrice: web3.utils.toHex(gasPrice),
          gas: web3.utils.toHex(gasLimit),
          to: contractAddressKCC,
          from: currentAccount,
          data: encoded_tx,
        });
        console.log(trx);
        if (trx.transactionHash) {
          return {
            status: true,
            transactionHash: trx.transactionHash,
            userAddress: web3WalletAddress,
          }; //buyKaiApi(currentAccount, trx.transactionHash);
        }
      }
    } catch (error) {
      setShow(false);
      setLoader(false);
      console.log(error);
      toast.error(error.message);
      return { status: false };
    }
  };

  const handleClose = () => {
    setShow(false);
    setProcessComplete(false);
  };
  return (
    <>
      {/* <section className="doublebox"> */}
      <section className="">
        <Container>
          <Toaster />
          <Row className="justify-content-center">
            <Col lg={4}>
              <div className="buykai-m">
                <div className="text-center">
                  <h2>BUY KAI</h2>
                </div>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Send USDT on</Form.Label>
                    <Form.Select
                      onChange={handleChange2}
                      value={selectedBlockchain}
                    >
                      {getBlockchains.map((item) => (
                        <>
                          <option value={item}>
                            {config.buyKaiChainName[item]}
                            {`(${item})`}{" "}
                          </option>
                        </>
                      ))}
                    </Form.Select>
                    {BlockchainSelectError ? (
                      <Form.Text className="text-danger">
                        Please Select an Blockchain
                      </Form.Text>
                    ) : (
                      <></>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Order Option</Form.Label>
                    <Form.Select value={selectedOption} onChange={handleChange}>
                      {options.map((option, index) => (
                        <option key={index} value={option}>
                          {option} {option === "Limit" && "(COMING SOON)"}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>KAI Wallet to receive KAI</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="0x"
                      className="h-auto"
                      onChange={(e) => {
                        methodsetWeb3WalletAddress(e);
                      }}
                      value={web3WalletAddress}
                    />
                    <div className="text-right" style={{ margiTop: " 3px" }}>
                      <p className="text-white mb-0">must be KRC20 </p>
                    
                     
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="email"
                      placeholder="Your Email Address"
                      className="h-auto"
                      onChange={(e) => {
                        changeEmailAddress(e);
                      }}
                      value={emailAddress}
                    />
                  </Form.Group>
                  {selectedOption == "Market" ? (
                    <Form.Group className="mb-3">
                      <Form.Label>Enter USDT</Form.Label>

                      <Form.Control
                        type="text"
                        placeholder="0.1"
                        className="h-auto"
                        onChange={(e) => {
                          changeUsdtAmount(e);
                        }}
                        value={usdtAmount}
                        pattern="\d+(\.\d{1,4})?"
                      />
                    </Form.Group>
                  ) : (
                    <Form.Group className="mb-3">
                      <Form.Label>Enter KAI</Form.Label>

                      <Form.Control
                        type="text"
                        placeholder="100"
                        className="h-auto"
                        // onChange={e=>{changeKaiAmount(e)}}
                        value={kaiAmount}
                      />
                    </Form.Group>
                  )}
                  <div className="mb-3">
                    <div className="w-100">
                      {selectedOption == "Market" ? (
                        <div className="d-flex justify-content-between">
                          <div className="p-0">Current Market Price</div>
                          <div className="p-0 text-right">
                            <p className="mb-0">{currentKaiRateInUSDT} USDT</p>
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-between">
                          <div className="p-0">
                            Set Limit Price**
                            <div className="p-0 text-right">
                              <p
                                className="mb-0"
                                onChange={(e) => changeCurrentRate(e)}
                              >
                                {currentKaiRateInUSDT} USDT
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* <div className="d-flex justify-content-between" >
                        <div className="p-0">How many USDT you will send</div>
                        <div className="p-0 text-right"><p className="mb-0">{usdtAmount} USDT</p></div>
                      </div> */}
                      <div className="d-flex justify-content-between">
                        <div className="p-0">
                          How many KAI you will receive*
                        </div>
                        <div className="p-0 text-right">
                          <p className="mb-0">{kaiAmount} KAI</p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="p-0">Our Fee</div>
                        <div className="p-0 text-right">
                          <p className="mb-0"> {fees}%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <Button
                      variant="success"
                      type="submit"
                      className="fw-bold"
                      onClick={(e) => buyKaiSubmit(e)}
                    >
                      BUY KAI
                    </Button>
                  </div>
                  <div>
                    <h3>Conditions:</h3>
                    <p className="text-white">
                      *Estimate number of KAI you will receive based on current
                      market price. Orders will be executed at market price
                      according to the order book
                    </p>
                    <div>
                      <div>
                        <p className="text-white mb-0">
                          1. Ensure you send USDT on KCC Network
                        </p>
                      </div>
                      <div>
                        <p className="text-white mb-0">
                          2. Ensure you KAI Wallet address is KRC20
                        </p>
                      </div>
                    </div>
                    <div className="text-right">
                      <p className="">
                        – we advise you to use the kardiachain app
                      </p>
                    </div>
                  </div>
                </Form>
                <footer className="text-center py-0">
                  <div className="f-logo">
                    <img src="images/main_logo.png" width="200px" />
                  </div>
                  <p className="text-white mb-0">
                    If there are any issues reach us on telegram{" "}
                  </p>
                  <a href="#">https://t.me/dappwin_io </a>
                </footer>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      {/* <Modal show={show} onHide={handleClose} size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
        
      </Modal> */}

      {/* <Modal size="lg" className="modal-dark"> */}
      <Modal
        show={show}
        size="sm"
        className="modal-dark"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
        backdrop="static"
      >
        {processComplete ? (
          <Modal.Header closeButton className="mainclose">
            <div>
              {/* <Modal.Title>Transaction Processing</Modal.Title> */}
              <h4>Transaction Processing</h4>
            </div>
          </Modal.Header>
        ) : (
          <Modal.Header>
            <Modal.Title>Transaction Processing</Modal.Title>
          </Modal.Header>
        )}

        <Modal.Body className="pt-0">
          <div className="amountdetail mt-0">
            <Row className="">
              <Col lg={12} className="text-center">
                <div className="head">
                  {loader ? (
                    <h4>Please wait...</h4>
                  ) : processComplete ? (
                    <h4>This may take up to 10 mins</h4>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Buykai;
