import { getRequest, postRequest, putRequest, deleteRequest, postRequestFormData } from "../coreFile/helper";



export const spinTheWheelOpts = (data) => {
  return getRequest('getSpinTheWheelOptions', data).then(res => { return res.data })
}
export const insertTransaction = (data) => {
    return postRequest('insertransaction', data).then(res => { return res.data })
 }

 export const getTotalKai = (data) => {
    return getRequest("getTotalKai",data).then((res) => {
      return res.data;
    });
  };

  export const getTotalSoldTickets = (data) => {
    return getRequest("getTotalSoldTickets",data).then((res) => {
      return res.data;
    });
  };


  export const updateEndRaffle = (data) => {
    return getRequest("updateEndRaffle",data).then((res) => {
      return res.data;
    });
  };
  
  

  export const loginuser = (data) => {
    return postRequest("loginuser",data).then((res) => {
      return res.data;
    });
  };

  

  export const getUserById = (data) => {
    return postRequest("getUserById",data).then((res) => {
      return res.data;
    });
  };

  export const getWinnerList = (data) => {
    return getRequest("getWinnerList",data).then((res) => {
      return res.data;
    });
  };


  export const sendMailReq = (data) => {
    return postRequest("sendMail",data).then((res) => {
      return res.data;
    });
  };

  export const sendWinnerMail = (data) => {
    return postRequest("sendWinnerMail",data).then((res) => {
      return res.data;
    });
  };

  
  export const setAllTransactionCompleted = (data) => {
    return getRequest("setAllTransactionCompleted",data).then((res) => {
      return res.data;
    });
  };


  export const getActivity = (data) => {
    return getRequest("getActivity",data).then((res) => {
      return res.data;
    });
  };

  export const getAllTransactionDetail = (data) => {
    return getRequest("getAllTransactionDetail",data).then((res) => {
      return res.data;
    });
  };



  export const contactus = (data) => {
    return postRequest("contactus",data).then((res) => {
      return res.data;
    });
  };

  
  export const officeDetails = (data) => {
    return getRequest("officeDetails",data).then((res) => {
      return res.data;
    });
  };


  

  
  export const activeUsers = (data) => {
    return getRequest("activeUsers",data).then((res) => {
      return res.data;
    });
  };

  export const discreption = (data) => {
    return getRequest("discreption",data).then((res) => {
      return res.data;
    });
  }

  export const updateStartRaffle = (data) => {
    return getRequest("admin/updateStartRaffle",data).then((res) => {
      return res.data;
    });
  }


  
  export const endRaflle = (data) => {
    return getRequest("endRaflle",data).then((res) => {
      return res.data;
    });
  }

  
  export const getTransactionDetailsByIndexNumber = (data) => {
    return postRequest("getTransactionDetailsByIndexNumber",data).then((res) => {
      return res.data;
    });
  }

   
  export const updateEmailInTransaction = (data) => {
    return postRequest("updateEmailInTransaction",data).then((res) => {
      return res.data;
    });
  }


  
  export const verifyEmailAndUserName = (data) => {
    return postRequest("verifyEmailAndUserName",data).then((res) => {
      return res.data;
    });
  }
  
  export const verifyUserName = (data) => {
    return postRequest("verifyUserName",data).then((res) => {
      return res.data;
    });
  }
  export const forgetPassword = (data) => {
    return postRequest("forgetPassword",data).then((res) => {
      return res.data;
    });
  }

   
  export const updatePassword = (data) => {
    return postRequest("updatePassword",data).then((res) => {
      return res.data;
    });
  }

  export const sendEmailOTP = (data) => {
    return postRequest("sendEmailOTP",data).then((res) => {
      return res.data;
    });
  }

   
  export const verifyOTP = (data) => {
    return postRequest("verifyOTP",data).then((res) => {
      return res.data;
    });
  }

  

  export const newUserRegister = (data) => {
    return postRequest("newUserRegister",data).then((res) => {
      return res.data;
    });
  }

   export const checkActiveAccount = (data) => {
    return postRequest("checkActiveAccount",data).then((res) => {
      return res.data;
    });
  }

  export const getUserlogin = (data) => {
    return postRequest("getUserlogin",data).then((res) => {
      return res.data;
    });
  }

  
  export const getUserTransaction = (data) => {
    return postRequest("getUserTransaction",data).then((res) => {
      return res.data;
    });
  }

  
  export const addProfilePictute = (data) => {
    return postRequestFormData("addProfilePictute",data).then((res) => {
      return res.data;
    });
  }

  export const addWalletAddress = (data) => {
    return postRequest("addWalletAddress",data).then((res) => {
      return res.data;
    });
  }

  export const getTransactionDetailsById = (data) => {
    return postRequest("getTransactionDetailsById",data).then((res) => {
      return res.data;
    });
  }

  
  export const checkReferralId = (data) => {
    return postRequest("checkReferralId",data).then((res) => {
      return res.data;
    });
  }

  
  export const sendEmailToSupport = (data) => {
    return postRequest("sendEmailToSupport",data).then((res) => {
      return res.data;
    });
  }



  
  export const checkEmailExist = (data) => {
    return postRequest("checkEmailExist",data).then((res) => {
      return res.data;
    });
  }

    export const updateUser = (data) => {
    return postRequest("updateUser",data).then((res) => {
      return res.data;
    });
  }
   export const checkUserId = (data) => {
    return postRequest("checkUserId",data).then((res) => {
      return res.data;
    });
  }
  export const registerUser = (data) => {
    return postRequest("registerUser",data).then((res) => {
      return res.data;
    });
  } 
    export const checkSpinAvailableByWalletAddress = (data) => {
    return postRequest("checkSpinAvailableByWalletAddress",data).then((res) => {
      return res.data;
    });

    
  }

  
  export const checkFreeSpinAvailableByWalletAddress = (data) => {
    return postRequest("checkFreeSpinAvailableByWalletAddress",data).then((res) => {
      return res.data;
    });

    
  }

  export const updateResult = (data) => {
    return postRequest("updateResult",data).then((res) => {
      return res.data;
    });
  }
  
  export const updateFreeSpinAPI = (data) => {
    return postRequest("updateFreeSpinAPI",data).then((res) => {
      return res.data;
    });
  }
  export const getAllSpinTransactions = (data) => {
    return getRequest("getAllSpinTransactions",data).then((res) => {
      return res.data;
    });
  }

  
  export const getAllSpinUsers = (data) => {
    return getRequest("getAllSpinUsers",data).then((res) => {
      return res.data;
    });
  }

  
  export const getFeesPercentageAPI = (data) => {
    return getRequest("getFeesPercentageAPI",data).then((res) => {
      return res.data;
    });
  }
  
  export const insertBuyKaiAPI = (data) => {
    return postRequest("insertBuyKaiAPI2",data).then((res) => {
      return res.data;
    });
  }

  export const chancesPercentageApi = (data) => {
    return getRequest("chancesPercentageApi",data).then((res) => {
      return res.data;
    });
  }

  export const discreptionSpinTheWheel = (data) => {
    return getRequest("discreptionSpinTheWheel",data).then((res) => {
      return res.data;
    });
  }
    export const getUSDTChains = (data) => {
    return getRequest("getUSDTChains",data).then((res) => {
      return res.data;
    });
  }
  export const getDepositAddress = (data) => {
    return postRequest("getDepositAddress",data).then((res) => {
      return res.data;
    });
    
  }
  export const currentKaiRateInUsdt = (data) => {
    return getRequest("currentKaiRateInUsdt",data).then((res) => {
      return res.data;
    });
  }