import React, { useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Header from "./directories/Header";
import Footer from "./directories/Footer";
import Modal from 'react-bootstrap/Modal';
import validator from 'validator'
import toast, { Toaster } from 'react-hot-toast';
import config from "../config/config";
import {sendMailReq,newUserRegister,checkReferralId,loginuser, verifyEmailAndUserName,verifyOTP,sendEmailOTP ,updateEmailInTransaction} from "../Action/user.action"
import Cookies from "js-cookie";


const ConnectWallet = (props) => {
    let userId = Cookies.get("userId")
    let token = Cookies.get("token")

    if(token){
        window.location.href = `${config.baseUrl}`;
    }
    const [show, setShow] = useState(false);
    const [form,setForm] = useState({email:'',userName:'',password:'',confirmPassword:'',rafferral_by:''})
    const [formError,setFormError] = useState({email:'',userName:'',password:'',confirmPassword:'',rafferral_by:''})
    const [otp,setOtp] = useState();
    const [userWalletAddress,setUserWalletAddress] = useState('')
    const handleShow = () => setShow(true);
 

const onChange=(e)=>{
   
    setForm({...form,[e.target.name]:e.target.value})

}

const submit=async(e)=>{
    e.preventDefault();
    const walletConnectivity = await walletConnectivityMethod();
    if(!walletConnectivity.status){
        return
    }
    
    let verify = verification();
   
    if(form.rafferral_by!=''){

    let checkreferralid = await checkReferralId({rafferal_by:form.rafferral_by})
  if(!(checkreferralid.status)){
    toast.error(checkreferralid.msg)
    return
  }
    }   
    if(await verify){
        let verifyAPI = await verifyEmailAndUserName(form);
        if(verifyAPI.status){
            let rafferral_id = Date.now()
            
            
            let data = {
                email:form.email,
                userName:form.userName,
                password:form.password,
                rafferral_id:rafferral_id,
                rafferral_by:form.rafferral_by,
                wallet_address:walletConnectivity.walletAddress
            }
            let result = await newUserRegister(data)
            if(!result.status){
                toast.error(result.msg)
                return
            }
          
            data.id=result.data
            let optAPI = await sendEmailOTP(data);
            if(optAPI.status){
                toast.success(optAPI.msg)
                handleShow()
            }
        }else{
        toast.error(verifyAPI.msg)
        }
    }else{

    }

}

const walletConnectivityMethod=async()=>{
    try{
       if(window.kardiachain){ console.log(window.kardiachain)}
 if(window.kardiachain){

if (window.kardiachain.isKaiWallet) {
    window.kardiachain.enable();
}
const accounts = await window.kardiachain.selectedAddress
setUserWalletAddress(accounts)
return {status:true,walletAddress:accounts}

}else if(window.ethereum){
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    setUserWalletAddress(accounts[0])
    return {status:true,walletAddress:accounts[0]}
}else if(!window.ethereum && !window.kardiachian){
    toast.error("Please install kardiachain wallet or any web3 wallet like metamask")
    return {status:false,walletAddress:""}
    }
    }catch(error){
console.log(error)
toast.error("something went wrong")

return {status:false,walletAddress:""}
    }
}


const verification=async()=>{
    let emialError = '';
    let userNameError = '';
    let passwordError = '';
    let confirmPasswordError = '';
    let rafferralError = '';
    
    if(!(validator.isEmail(form.email)) || form.email==''){
        emialError='Invalide Email'
    }
    if(form.userName == ''){
        userNameError='Invalid user name'
    }
    if(form.password == '' ){
        passwordError = 'Invalid Password'
    }
    if(form.password.length <6 ){
        passwordError = 'Invalid Password. Password must be greater than 6 characters'
    }
    if(form.confirmPassword == ''){
        confirmPasswordError = 'Invalid Password'
    }
    if(form.confirmPassword != form.password){
        confirmPasswordError = 'Password and confirm Password does not match'
    }
    


    if(emialError == ''  && userNameError == '' && passwordError == ''  && confirmPasswordError == ''
    ){
        setFormError({email:emialError,userName:userNameError,password:passwordError,confirmPassword:confirmPasswordError
        })
        return true;
    }else{
        setFormError({email:emialError,userName:userNameError,password:passwordError,confirmPassword:confirmPasswordError
        })
        return false;
    }
}

const verifyOtp=async(e)=>{
e.preventDefault();
let data = {
    otp:otp,
    userName:form.userName,
    email:form.email,
    password:form.password
}
let verificationOtp = await verifyOTP(data)
if(verificationOtp.status){
    toast.success(verificationOtp.msg)
    let loginuserResult = await loginuser(data)
    Cookies.set("userId", loginuserResult.data);

    setTimeout(() => {
        window.location.href = `${config.baseUrl}`;

    }, 1000);
}else{
    toast.error(verificationOtp.msg)

}
}

    return (

        <>
         <Toaster />
            <Header />
            <section className='connect_wallet pt-5 mb-5'>
                <Container>
                    <Row>
                        <Col md={6} xs={12} className='mx-auto p-4 py-4 wallet_form'>
                        <div className='text-center mb-4'>
                                <h2>Sign Up</h2>
                            </div>
                            <Form className=' mb-4' autoComplete='off' onSubmit={e=>{submit(e)}} >
                                <Form.Group className="mb-3">
                                    <Form.Label >User Name</Form.Label>
                                    <Form.Control type="text" placeholder="Choose Your User Name" name="userName" value={form.userName}  onChange={e=>{onChange(e)}} />
                                    {
                                    formError.userName==''
                                    ?
                                    <span></span>
                                    :
                                    <span style={{color:'red'}}>{formError.userName}</span>
                                    }
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label >Email Id</Form.Label>
                                    <Form.Control type="email" placeholder="Your Email Address"  name="email" value={form.email} onChange={e=>{onChange(e)}} />
                                    {
                                    formError.email==''
                                    ?
                                    <span></span>
                                    :
                                    <span style={{color:'red'}}>{formError.email}</span>
                                    }
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label >Password</Form.Label>
                                    <Form.Control type="password" placeholder="Create A Password"  name="password" value={form.password} onChange={e=>{onChange(e)}} />
                                    {
                                    formError.password==''
                                    ?
                                    <span></span>
                                    :
                                    <span style={{color:'red'}}>{formError.password}</span>
                                    }
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label >Confirm Password</Form.Label>
                                    <Form.Control type="password" placeholder="Confirm Your Password"  name="confirmPassword" value={form.confirmPassword} onChange={e=>{onChange(e)}} />
                                    {
                                    formError.confirmPassword==''
                                    ?
                                    <span></span>
                                    :
                                    <span style={{color:'red'}}>{formError.confirmPassword}</span>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label >Referral ID </Form.Label>
                                    <Form.Control type="text" placeholder="Referral Link (if you have one)"  name="rafferral_by" value={form.rafferral_by} onChange={e=>{onChange(e)}} />
                                    {
                                    formError.rafferral_by==''
                                    ?
                                    <span></span>
                                    :
                                    <span style={{color:'red'}}>{formError.rafferral_by}</span>
                                    }
                                </Form.Group>
                 
                                <Button variant="primary" type='submit' className='themebtn me-3' onClick={e=>{submit(e)}}>
                                    Submit
                                </Button>

                            </Form>
                            <p className='mb-2'>Already Have an account?</p><a href={`${config.baseUrl}login`}>Login</a>

                        </Col>
                    </Row>
                </Container>
            </section>

            <Footer />


            {/*-------------------------- Modal Connect Wallet --------------------------- */}

            <Modal show={show}  className='verify_code' {...props}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                <Modal.Header closeButton>
                    <Modal.Title>Please Check Your Email</Modal.Title>
                    {/* <Modal
                        {...props}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    ></Modal> */}
                </Modal.Header>
                <Modal.Body className='py-0'>
                    <Form className='' autoComplete='off' onSubmit={e => { verifyOtp(e) }}>
                        <Form.Group className="mb-3">
                            <Form.Label>Verify Code</Form.Label>
                            <Form.Control type="number" placeholder="Enter One Time Password" value={otp} onChange={e=>{setOtp(e.target.value)}} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="primary" onClick={e=>{verifyOtp(e)}} className='themebtn mx-auto'>
                    SUBMIT
                    </Button>
                </Modal.Footer>
            </Modal>



        </>

    )
}

export default ConnectWallet;
