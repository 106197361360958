import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Navbar,
  Button,
  NavLink,
  Offcanvas,
  Nav,
  Form,
  NavDropdown,
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
// import Navbar from 'react-bootstrap/Navbar';
import { getUserById } from "../../Action/user.action";
import Cookies from "js-cookie";

import { Link } from "react-router-dom";
import config from "../../config/config";
import Web3 from "web3";
const Header = () => {
  const [userID, setUserID] = useState("");
  const [userData, setuserDate] = useState([]);

  useEffect(() => {
    let UserId = Cookies.get("userId");
    if (UserId) {
      getUserApi(UserId);

      setUserID(UserId);
    }
  }, []);

  const getUserApi = async (id) => {
    let getUser = await getUserById({ userId: id });
    if (getUser.status) {
      setuserDate(getUser.data);
    }
  };

  const connectMetaMask = async (e) => {
    window.location.href = `${config.baseUrl}login`;
  };

  const logout = (e) => {
    e.preventDefault();

    Cookies.remove("userId");
    Cookies.remove("token");

    window.location.href = `${config.baseUrl}`;
  };

  return (
    <>
      <header className="sticky-top">
        {["lg"].map((expand) => (
          <Navbar key={expand} bg="transparent" expand={expand}>
            <Container>
              <Navbar.Brand href={`${config.baseUrl}`}>
                <img src="images/main_logo.png" />
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <div className="navbar-brand">
                      <img
                        src="images/logo.png"
                        width="120px"
                        className="sidebarlogo"
                      />
                    </div>
                  </Offcanvas.Title>
                 
                 
            
                </Offcanvas.Header>
               
                <Offcanvas.Body>
               
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    {/* <Nav.Link href="#">How It Works</Nav.Link> */}
                    <Nav.Link href={`${config.baseUrl}contactus`}>
                      Contact us
                    </Nav.Link>
                  </Nav>
                  <Form className="d-flex">
                    <div className="connect-btn  pe-3">
                      {userID !== "" &&
                      userID !== undefined &&
                      userID !== null &&
                      userData.length > 0 ? (
                        <Button
                          className="border-btn px-4"
                          onClick={(e) => logout(e)}
                        >
                          {userData[0].userName}{" "}
                          <img src="images/kiachain.png" />
                          <span style={{ color: "yello" }}>Log Out</span>
                        </Button>
                      ) : (
                        <Button
                          onClick={(e) => connectMetaMask(e)}
                          className="border-btn w-auto px-4"
                        >
                          LOGIN
                          <img src="images/kiachain.png" />
                        </Button>
                      )}
                    </div>
                    <div className="connect-btn buyKaibtn">
                    <a
                        href={`${config.baseUrl}buykai`}
                        class="theme-green-btn px-4 py-1 fw-medium d-flex align-items-center"
                      >
                        BUY KAI
                      </a>
                      </div>
                    {userID !== "" &&
                    userID !== undefined &&
                    userID !== null ? (
                      <div>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            <span>
                              {userData.length > 0 ? (
                                userData[0].profile_pic != "" &&
                                userData[0].profile_pic != null ? (
                                  <img
                                    src={
                                      `${config.imageURL}` +
                                      `${userData[0].profile_pic}`
                                    }
                                    className="profilepic"
                                  />
                                ) : (
                                  <img
                                    src="images/profileico.png"
                                    className="profilepic"
                                  />
                                )
                              ) : (
                                ""
                              )}
                              {/* <img src="images/profileico.png" className="profilepic" /> */}
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="">
                            <Dropdown.Item href={`${config.baseUrl}myprofile`}>
                              My Activity
                            </Dropdown.Item>
                            <Dropdown.Item href={`${config.baseUrl}setting`}>
                              Profile
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Form>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </header>
    </>
  );
};
export default Header;
